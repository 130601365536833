<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="dv-content" *ngIf="stammdaten$ | async as stammdaten">
    <div *ngIf="editMode; else viewModeTemplate">
        <fieldset ngModelGroup="dv-edit-gemeinde-bg">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group mt-2">
                        <mat-checkbox
                            id="gutscheinSelberAusgestellt_id"
                            name="gutscheinSelberAusgestellt_id"
                            [(ngModel)]="stammdaten.gutscheinSelberAusgestellt"
                        >
                            {{ 'GUTSCHEIN_SELBER_AUSGESTELLT' | translate }}
                        </mat-checkbox>
                    </div>
                    <div
                        *ngIf="!stammdaten.gutscheinSelberAusgestellt"
                        class="form-group mt-2"
                    >
                        <label
                            class="md-no-float required"
                            translate
                            for="gemeindeAusgabestelle_id"
                            >GUTSCHEINAUSGABESTELLE</label
                        >
                        <mat-select
                            [(ngModel)]="stammdaten.gemeindeAusgabestelle"
                            id="gemeindeAusgabestelle_id"
                            name="gemeindeAusgabestelle_id"
                            class="form-control"
                            #gemeindeAusgabestelle="ngModel"
                            [compareWith]="compareGemeinde"
                            [required]="true"
                        >
                            <mat-option
                                *ngFor="
                                    let ausgabestelle of gemeindeList$ | async
                                "
                                [value]="ausgabestelle"
                            >
                                {{ ausgabestelle.name }}
                            </mat-option>
                        </mat-select>
                        <dv-error-messages
                            [errorObject]="gemeindeAusgabestelle.errors"
                            inputId="gemeindeAusgabestelle_id"
                        >
                        </dv-error-messages>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--                bankverbindung für auszahlungen-->
                <div class="col-sm-6">
                    <h3 translate for="korrespondenzsprache_id">
                        BANKVERBINDUNG
                    </h3>
                    <!-- Kontoinhaber -->
                    <div class="form-group">
                        <label class="required" translate for="kontoinhaber_id"
                            >KONTO_INHABER</label
                        >
                        <input
                            id="kontoinhaber_id"
                            type="text"
                            name="kontoinhaber_id"
                            #nameKontoinhaber="ngModel"
                            required
                            [(ngModel)]="stammdaten.kontoinhaber"
                            [maxlength]="70"
                            class="form-control"
                        />
                        <dv-error-messages
                            [errorObject]="nameKontoinhaber.errors"
                            inputId="kontoinhaber_id"
                        ></dv-error-messages>
                    </div>
                    <!-- BIC/SWIFT-Nummer -->
                    <div class="form-group">
                        <label class="required" translate for="bic_id"
                            >BIC_SWIFT_NUMMER</label
                        >
                        <input
                            id="bic_id"
                            type="text"
                            name="bic_id"
                            #nameBIC="ngModel"
                            required
                            [(ngModel)]="stammdaten.bic"
                            [maxlength]="255"
                            class="form-control"
                        />
                        <dv-error-messages
                            [errorObject]="nameBIC.errors"
                            inputId="bic_id"
                        ></dv-error-messages>
                    </div>
                    <!-- IBAN-Nummer -->
                    <div class="form-group">
                        <label class="required" translate for="iban_id"
                            >IBAN_NUMMER</label
                        >
                        <input
                            id="iban_id"
                            type="text"
                            name="iban_id"
                            #nameIBAN="ngModel"
                            required
                            ngxIban
                            isNotQrIbanN
                            [(ngModel)]="stammdaten.iban"
                            [maxlength]="255"
                            class="form-control"
                        />
                        <dv-error-messages
                            [errorObject]="nameIBAN.errors"
                            inputId="iban_id"
                        ></dv-error-messages>
                    </div>

                    <!--                    benachrichtigungen bei verfügungen per email senden-->
                    <div class="form-group">
                        <div class="form-group checkbox-with-tooltip">
                            <div>
                                <mat-checkbox
                                    id="benachrichtigungBgEmailAuto_id"
                                    name="benachrichtigungBgEmailAuto_id"
                                    [(ngModel)]="
                                        stammdaten.benachrichtigungBgEmailAuto
                                    "
                                >
                                    {{
                                        'BENACHRICHTIGUNGEN_EMAIL_SENDEN'
                                            | translate
                                    }}
                                </mat-checkbox>
                            </div>
                            <dv-tooltip
                                id="benachrichtigungBgEmailAuto-tooltip"
                                [text]="
                                    'BENACHRICHTIGUNGEN_EMAIL_SENDEN_HELP'
                                        | translate
                                "
                            ></dv-tooltip>
                        </div>
                    </div>

                    <!--                    standard fusszeile bei verfügungen-->
                    <div class="form-group">
                        <div class="form-group checkbox-with-tooltip">
                            <div>
                                <mat-checkbox
                                    id="standardDokSignature_id"
                                    name="standardDokSignature_id"
                                    [(ngModel)]="
                                        stammdaten.standardDokSignature
                                    "
                                >
                                    {{ 'STANDARD_DOK_SIGNATURE' | translate }}
                                </mat-checkbox>
                            </div>
                            <dv-tooltip
                                id="standardDokSignature_tooltip"
                                [text]="
                                    'STANDARD_DOK_SIGNATURE_HELP' | translate
                                "
                            ></dv-tooltip>
                        </div>
                        <div *ngIf="!stammdaten.standardDokSignature">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div>
                                        <div class="form-group">
                                            <label
                                                translate
                                                for="standardDokTitle_id"
                                                >STANDARD_DOK_TITLE</label
                                            >
                                            <input
                                                id="standardDokTitle_id"
                                                type="text"
                                                name="standardDokTitle_id"
                                                #standardDokTitle="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.standardDokTitle
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    standardDokTitle.errors
                                                "
                                                inputId="standardDokTitle_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Unterschrift 1 Titel-->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            translate
                                            for="standardDokUnterschriftTitel_id"
                                            >STANDARD_DOK_UNTERSCHRIFT1_TITEL</label
                                        >
                                        <input
                                            id="standardDokUnterschriftTitel_id"
                                            type="text"
                                            name="standardDokUnterschriftTitel_id"
                                            #standardDokUnterschriftTitel="ngModel"
                                            [(ngModel)]="
                                                stammdaten.standardDokUnterschriftTitel
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                standardDokUnterschriftTitel.errors
                                            "
                                            inputId="standardDokUnterschriftTitel_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                                <!-- Unterschrift 1 Name -->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            translate
                                            for="standardDokUnterschriftName_id"
                                        >
                                            STANDARD_DOK_UNTERSCHRIFT1_NAME</label
                                        >
                                        <input
                                            id="standardDokUnterschriftName_id"
                                            type="text"
                                            name="standardDokUnterschriftName_id"
                                            #standardDokUnterschriftName="ngModel"
                                            [(ngModel)]="
                                                stammdaten.standardDokUnterschriftName
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                standardDokUnterschriftName.errors
                                            "
                                            inputId="standardDokUnterschriftName_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Unterschrift 2 Titel-->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            translate
                                            for="standardDokUnterschriftTitel2_id"
                                        >
                                            STANDARD_DOK_UNTERSCHRIFT2_TITEL</label
                                        >
                                        <input
                                            id="standardDokUnterschriftTitel2_id"
                                            type="text"
                                            name="standardDokUnterschriftTitel2_id"
                                            #standardDokUnterschriftTitel2="ngModel"
                                            [(ngModel)]="
                                                stammdaten.standardDokUnterschriftTitel2
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                standardDokUnterschriftTitel2.errors
                                            "
                                            inputId="standardDokUnterschriftTitel2_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                                <!-- Unterschrift 2 Name -->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            translate
                                            for="standardDokUnterschriftName2_id"
                                        >
                                            STANDARD_DOK_UNTERSCHRIFT2_NAME</label
                                        >
                                        <input
                                            id="standardDokUnterschriftName2_id"
                                            type="text"
                                            name="standardDokUnterschriftName2_id"
                                            #standardDokUnterschriftName2="ngModel"
                                            [(ngModel)]="
                                                stammdaten.standardDokUnterschriftName2
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                standardDokUnterschriftName2.errors
                                            "
                                            inputId="standardDokUnterschriftName2_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Verantwortlicher Tagesschule per Email informieren, nachdem ein Mischgesuch verfügt wurde. (wird nur angezeigt wenn Mischgesuche möglich sind) -->
                    <div
                        class="form-group"
                        *ngIf="stammdaten.gemeinde.angebotTS"
                    >
                        <div class="form-group checkbox-with-tooltip">
                            <div>
                                <mat-checkbox
                                    id="verantwortlicherTSInformieren_id"
                                    name="verantwortlicherTSInformieren_id"
                                    [(ngModel)]="
                                        stammdaten.tsVerantwortlicherNachVerfuegungBenachrichtigen
                                    "
                                >
                                    {{
                                        'TS_VERANTWORTLICHER_NACH_VERFUEGUNG_BENACHRICHTIGEN'
                                            | translate
                                    }}
                                </mat-checkbox>
                            </div>
                            <div class="tooltip-margin">
                                <dv-tooltip
                                    id="verantwortlicherTSInformieren_tooltip"
                                    [text]="
                                        'TS_VERANTWORTLICHER_NACH_VERFUEGUNG_BENACHRICHTIGEN_HELP'
                                            | translate
                                    "
                                ></dv-tooltip>
                            </div>
                        </div>
                    </div>

                    <!--Antragsteller bei Öffnung der Gesuchsperiode per Email informieren-->
                    <div class="form-group">
                        <div class="form-group checkbox-with-tooltip">
                            <div>
                                <mat-checkbox
                                    id="emailBeiGesuchsperiodeOeffnung"
                                    name="emailBeiGesuchsperiodeOeffnung"
                                    [(ngModel)]="
                                        stammdaten.emailBeiGesuchsperiodeOeffnung
                                    "
                                >
                                    {{
                                        'EMAIL_BEI_GESUCHSPERIODE_OEFFNUNG'
                                            | translate
                                    }}
                                </mat-checkbox>
                            </div>
                            <div class="tooltip-margin">
                                <dv-tooltip
                                    id="emailBeiGesuchsperiodeOeffnung_tooltip"
                                    [text]="
                                        'EMAIL_BEI_GESUCHSPERIODE_OEFFNUNG_HELP'
                                            | translate
                                    "
                                ></dv-tooltip>
                            </div>
                        </div>
                    </div>
                    <!--Zusätzlicher Text in Verfügung-->
                    <div class="form-group">
                        <div class="form-group checkbox-with-tooltip">
                            <div>
                                <mat-checkbox
                                    id="zusatzTextBG_id"
                                    name="zusatzTextBG_id"
                                    [(ngModel)]="
                                        stammdaten.hasZusatzTextVerfuegung
                                    "
                                    (change)="zusatzTextBgHasChange(stammdaten)"
                                >
                                    {{
                                        'ZUSAETZLICHER_TEXT_VERFUEGUNG_JA'
                                            | translate
                                    }}
                                </mat-checkbox>
                            </div>
                            <dv-tooltip
                                id="zusatzText_tooltip"
                                [text]="
                                    'ZUSAETZLICHER_TEXT_VERFUEGUNG_HELP'
                                        | translate
                                "
                            ></dv-tooltip>
                        </div>
                        <div *ngIf="stammdaten.hasZusatzTextVerfuegung">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <dv-input-container-x
                                            [verticalAlign]="true"
                                        >
                                            <label
                                                class="required"
                                                translate
                                                for="zusatzText_id"
                                                >ZUSAETZLICHER_TEXT_VERFUEGUNG_TITEL</label
                                            >
                                            <mat-form-field
                                                floatLabel="always"
                                                class="no-margin-left"
                                            >
                                                <textarea
                                                    matInput
                                                    id="zusatzText_id"
                                                    name="nameZusatzText"
                                                    #nameZusatzText="ngModel"
                                                    [(ngModel)]="
                                                        stammdaten.zusatzTextVerfuegung
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    required
                                                ></textarea>
                                                <dv-error-messages
                                                    [errorObject]="
                                                        nameZusatzText.errors
                                                    "
                                                    inputId="zusatzTextTitle_id"
                                                ></dv-error-messages>
                                            </mat-form-field>
                                        </dv-input-container-x>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Betreuungsgutscheine anbieten ab -->
                    <div class="form-group mt-5">
                        <h3 translate>BEGU_START</h3>
                        <p>{{ beguStartStr }}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <!-- Standardverantwortliche BG -->
                    <div class="form-group">
                        <h3 translate for="verantwortliche_bg_id">
                            ALT_STANDARD_VERANTWORTLICHE
                        </h3>
                        <mat-select
                            [(ngModel)]="stammdaten.defaultBenutzerBG"
                            id="verantwortliche_bg_id"
                            name="verantwortliche_bg_id"
                            class="form-control"
                            #nameVerantwortlicheBG="ngModel"
                            [compareWith]="compareBenutzer"
                        >
                            <mat-option
                                *ngFor="let vbg of stammdaten.benutzerListeBG"
                                [value]="vbg"
                            >
                                {{ vbg.vorname }} {{ vbg.nachname }}
                            </mat-option>
                        </mat-select>
                        <dv-error-messages
                            [errorObject]="nameVerantwortlicheBG.errors"
                            inputId="verantwortliche_bg_id"
                        ></dv-error-messages>
                    </div>
                    <!--                alternative kontaktadresse-->
                    <div class="form-group mt-5">
                        <div class="form-group">
                            <mat-checkbox
                                id="altBGAdresse_id"
                                name="altBGAdresse_id"
                                [(ngModel)]="altBGAdresse"
                                (ngModelChange)="altBGAdresseHasChange($event)"
                            >
                                {{ 'ALTERNATIVE_ADRESSE' | translate }}
                            </mat-checkbox>
                        </div>
                        <div *ngIf="altBGAdresse">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div>
                                        <div class="form-group">
                                            <label
                                                class="required"
                                                translate
                                                for="anschrift_bg_id"
                                                >ADRESSE_ANSCHRIFT</label
                                            >
                                            <input
                                                id="anschrift_bg_id"
                                                type="text"
                                                name="anschrift_bg_id"
                                                #nameAnschriftBG="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.bgAdresse
                                                        .organisation
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    nameAnschriftBG.errors
                                                "
                                                inputId="anschrift_bg_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Strasse -->
                                <div class="col-sm-10">
                                    <div class="form-group">
                                        <label
                                            class="required"
                                            translate
                                            for="strasse_bg_id"
                                            >ADRESSE_STRASSE</label
                                        >
                                        <input
                                            id="strasse_bg_id"
                                            type="text"
                                            name="strasse_bg_id"
                                            #nameStrasseBG="ngModel"
                                            [(ngModel)]="
                                                stammdaten.bgAdresse.strasse
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="nameStrasseBG.errors"
                                            inputId="strasse_bg_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                                <!-- Hausnummer -->
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label translate for="hausnummer_bg_id"
                                            >ADRESSE_NUMMER</label
                                        >
                                        <input
                                            id="hausnummer_bg_id"
                                            type="text"
                                            name="hausnummer_bg_id"
                                            #nameHausnummerBG="ngModel"
                                            [(ngModel)]="
                                                stammdaten.bgAdresse.hausnummer
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                nameHausnummerBG.errors
                                            "
                                            inputId="hausnummer_bg_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- PLZ -->
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label
                                            class="required"
                                            translate
                                            for="plz_bg_id"
                                            >ADRESSE_PLZ</label
                                        >
                                        <input
                                            id="plz_bg_id"
                                            type="text"
                                            name="plz_bg_id"
                                            #namePLZBG="ngModel"
                                            [(ngModel)]="
                                                stammdaten.bgAdresse.plz
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="namePLZBG.errors"
                                            inputId="plz_bg_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                                <!-- Ort -->
                                <div class="col-sm-10">
                                    <div class="form-group">
                                        <label
                                            class="required"
                                            translate
                                            for="ort_bg_id"
                                            >ADRESSE_ORT</label
                                        >
                                        <input
                                            id="ort_bg_id"
                                            type="text"
                                            name="ort_bg_id"
                                            #nameOrtBG="ngModel"
                                            [(ngModel)]="
                                                stammdaten.bgAdresse.ort
                                            "
                                            [maxlength]="255"
                                            class="form-control"
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="nameOrtBG.errors"
                                            inputId="ort_bg_id"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Alternative Email -->
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label translate for="email_bg_id"
                                        >ALTERNATIVE_EMAIL
                                        <dv-tooltip
                                            [text]="
                                                'ANGABEN_VON_STAMMDATEN'
                                                    | translate
                                            "
                                        ></dv-tooltip>
                                    </label>
                                    <input
                                        id="email_bg_id"
                                        type="email"
                                        name="email_bg_id"
                                        #nameEmailBG="ngModel"
                                        [(ngModel)]="stammdaten.bgEmail"
                                        class="form-control"
                                        email
                                    />
                                    <dv-error-messages
                                        [errorObject]="nameEmailBG.errors"
                                        inputId="email_bg_id"
                                    ></dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Alternative Telefonnummer -->
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label translate for="telefonnummer_bg_id"
                                        >ALTERNATIVE_TELEFONNUMMER
                                        <dv-tooltip
                                            [text]="
                                                'ANGABEN_VON_STAMMDATEN'
                                                    | translate
                                            "
                                        ></dv-tooltip>
                                    </label>
                                    <input
                                        id="telefonnummer_bg_id"
                                        type="text"
                                        name="telefonnummer_bg_id"
                                        #nameTelefonnummerBG="ngModel"
                                        [(ngModel)]="stammdaten.bgTelefon"
                                        [pattern]="CONSTANTS.PATTERN_PHONE"
                                        class="form-control"
                                    />
                                    <dv-error-messages
                                        [errorObject]="
                                            nameTelefonnummerBG.errors
                                        "
                                        inputId="telefonnummer_bg_id"
                                    ></dv-error-messages>
                                </div>
                            </div>
                        </div>

                        <!-- Alternative Beschwerde-Adresse -->
                        <div class="mt-5">
                            <h3 translate for="keineBeschwerdeAdresse_id">
                                BESCHWERDE_ADRESSE
                                <dv-tooltip
                                    inputId="keineBeschwerdeAdresse_id"
                                    [text]="getAdressTranslation()"
                                ></dv-tooltip>
                            </h3>

                            <div class="form-group">
                                <mat-radio-group
                                    name="creationType"
                                    [(ngModel)]="
                                        stammdaten.standardRechtsmittelbelehrung
                                    "
                                    required
                                >
                                    <mat-radio-button [value]="true">{{
                                        'STANDARD_TEXT' | translate
                                    }}</mat-radio-button>
                                    &nbsp;
                                    <mat-radio-button [value]="false"
                                        >{{
                                            'BENUTZERDEFINIERTER_TEXT'
                                                | translate
                                        }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div
                                class="row"
                                *ngIf="
                                    !stammdaten.standardRechtsmittelbelehrung
                                "
                            >
                                <div class="col-sm-12">
                                    <div
                                        class="form-group"
                                        *ngIf="
                                            stammdaten.korrespondenzspracheDe
                                        "
                                    >
                                        <label
                                            class="required"
                                            translate
                                            for="rechtsmittelbelehrung_deutsch_textarea"
                                            >RECHTSMITTELBELEHRUNG_TEXT_DE</label
                                        >

                                        <textarea
                                            md-no-autogrow
                                            class="form-control"
                                            rows="6"
                                            required
                                            maxlength="4000"
                                            id="rechtsmittelbelehrung_deutsch_textarea"
                                            #nameRechtmittelDE="ngModel"
                                            name="rechtsmittelbelehrung_deutsch_textarea"
                                            [(ngModel)]="
                                                stammdaten.rechtsmittelbelehrung
                                                    .textDeutsch
                                            "
                                        >
                                        </textarea>
                                        <dv-error-messages
                                            [errorObject]="
                                                nameRechtmittelDE.errors
                                            "
                                            inputId="rechtsmittelbelehrung_deutsch_textarea"
                                        ></dv-error-messages>
                                    </div>
                                    <div
                                        class="form-group"
                                        id="rechtsmittelbelehrung-franzoesisch"
                                        *ngIf="
                                            stammdaten.korrespondenzspracheFr
                                        "
                                    >
                                        <label
                                            class="required"
                                            translate
                                            for="rechtsmittelbelehrung_franzoesisch_textarea"
                                            >RECHTSMITTELBELEHRUNG_TEXT_FR</label
                                        >

                                        <textarea
                                            md-no-autogrow
                                            class="form-control"
                                            rows="6"
                                            required
                                            maxlength="4000"
                                            id="rechtsmittelbelehrung_franzoesisch_textarea"
                                            #nameRechtmittelFR="ngModel"
                                            name="rechtsmittelbelehrung_franzoesisch_textarea"
                                            [(ngModel)]="
                                                stammdaten.rechtsmittelbelehrung
                                                    .textFranzoesisch
                                            "
                                        >
                                        </textarea>
                                        <dv-error-messages
                                            [errorObject]="
                                                nameRechtmittelFR.errors
                                            "
                                            inputId="rechtsmittelbelehrung_franzoesisch_textarea"
                                        ></dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-group checkbox-with-tooltip"
                                *ngIf="stammdaten.standardRechtsmittelbelehrung"
                            >
                                <mat-checkbox
                                    id="keineBeschwerdeAdresse_id"
                                    name="keineBeschwerdeAdresse_id"
                                    [(ngModel)]="keineBeschwerdeAdresse"
                                    (ngModelChange)="
                                        keineBeschwerdeAdresseChanged($event)
                                    "
                                >
                                    {{
                                        'BESCHWERDE_ADRESSE_IDENTISCH_KONTAKT'
                                            | translate
                                    }}
                                </mat-checkbox>
                            </div>
                            <div
                                *ngIf="
                                    !keineBeschwerdeAdresse &&
                                    stammdaten.beschwerdeAdresse &&
                                    stammdaten.standardRechtsmittelbelehrung
                                "
                            >
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div>
                                            <div class="form-group">
                                                <label
                                                    class="required"
                                                    translate
                                                    for="anschrift_ba_id"
                                                    >ADRESSE_ANSCHRIFT</label
                                                >
                                                <input
                                                    id="anschrift_ba_id"
                                                    type="text"
                                                    name="anschrift_ba_id"
                                                    #nameAnschriftBA="ngModel"
                                                    [(ngModel)]="
                                                        stammdaten
                                                            .beschwerdeAdresse
                                                            .organisation
                                                    "
                                                    [maxlength]="255"
                                                    class="form-control"
                                                    required
                                                    placeholder="{{
                                                        'ADRESSE_REGIERUNGSSTADTHALTERAMT_PLACEHOLDER'
                                                            | translate
                                                    }}"
                                                />
                                                <dv-error-messages
                                                    [errorObject]="
                                                        nameAnschriftBA.errors
                                                    "
                                                    inputId="anschrift_ba_id"
                                                ></dv-error-messages>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- Strasse -->
                                    <div class="col-sm-10">
                                        <div class="form-group">
                                            <label
                                                class="required"
                                                translate
                                                for="strasse_ba_id"
                                                >ADRESSE_STRASSE</label
                                            >
                                            <input
                                                id="strasse_ba_id"
                                                type="text"
                                                name="strasse_ba_id"
                                                #nameStrasseBA="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.beschwerdeAdresse
                                                        .strasse
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    nameStrasseBA.errors
                                                "
                                                inputId="strasse_ba_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                    <!-- Hausnummer -->
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label
                                                translate
                                                for="hausnummer_ba_id"
                                                >ADRESSE_NUMMER</label
                                            >
                                            <input
                                                id="hausnummer_ba_id"
                                                type="text"
                                                name="hausnummer_ba_id"
                                                #nameHausnummerBA="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.beschwerdeAdresse
                                                        .hausnummer
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    nameHausnummerBA.errors
                                                "
                                                inputId="hausnummer_ba_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- PLZ -->
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label
                                                class="required"
                                                translate
                                                for="plz_ba_id"
                                                >ADRESSE_PLZ</label
                                            >
                                            <input
                                                id="plz_ba_id"
                                                type="text"
                                                name="plz_ba_id"
                                                #namePLZBA="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.beschwerdeAdresse
                                                        .plz
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="namePLZBA.errors"
                                                inputId="plz_ba_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                    <!-- Ort -->
                                    <div class="col-sm-10">
                                        <div class="form-group">
                                            <label
                                                class="required"
                                                translate
                                                for="ort_ba_id"
                                                >ADRESSE_ORT</label
                                            >
                                            <input
                                                id="ort_ba_id"
                                                type="text"
                                                name="ort_ba_id"
                                                #nameOrtBA="ngModel"
                                                [(ngModel)]="
                                                    stammdaten.beschwerdeAdresse
                                                        .ort
                                                "
                                                [maxlength]="255"
                                                class="form-control"
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="nameOrtBA.errors"
                                                inputId="ort_ba_id"
                                            ></dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <dv-accordion
                        class="konfigurationList"
                        allow-multiple-sections="true"
                    >
                        <ng-container
                            *ngFor="
                                let konf of konfigurationsListe;
                                let i = index
                            "
                        >
                            <dv-accordion-tab
                                [tabid]="i"
                                *ngIf="
                                    konfigurationsListe[
                                        i
                                    ].gesuchsperiode.gueltigkeit.gueltigBis.isAfter(
                                        beguStartDatum
                                    )
                                "
                            >
                                <tab-title>
                                    <div class="message-header">
                                        <p class="fa fa-angle-right"></p>
                                        <span
                                            translate
                                            [translateParams]="{
                                                fromTo: konf.gesuchsperiodeName,
                                                status: konf.gesuchsperiodeStatusName
                                            }"
                                        >
                                            GEMEINDE_TAB_BG_GP_TITLE
                                        </span>
                                    </div>
                                </tab-title>
                                <tab-body>
                                    <div class="row">
                                        <!-- editable -->
                                        <div class="row">
                                            <div
                                                *ngIf="
                                                    isKonfigurationEditable(
                                                        konfigurationsListe[i]
                                                    )
                                                "
                                                class="col-sm-6"
                                            >
                                                <!-- BG bis Schulstufe -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigBeguBisUndMitSchulstufe
                                                        ) &&
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                >
                                                    <label
                                                        class="required"
                                                        translate
                                                        >BEGU_KITAS_BIS_UND_MIT</label
                                                    >
                                                    <mat-select
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigBeguBisUndMitSchulstufe
                                                        "
                                                        (selectionChange)="
                                                            changeKonfigBeguBisUndMitSchulstufe(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                        id="begu_kitas_bis_und_mit_id_{{
                                                            i
                                                        }}"
                                                        name="begu_kitas_bis_und_mit_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let etv of einschulungTypGemeindeValues
                                                            "
                                                            [value]="etv"
                                                            >{{
                                                                etv | translate
                                                            }}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                                <!-- Kontingentierung -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKontingentierung
                                                        ) &&
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                >
                                                    <mat-checkbox
                                                        id="kontingentierung_id_{{
                                                            i
                                                        }}"
                                                        name="kontingentierung_id_{{
                                                            i
                                                        }}"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKontingentierung
                                                        "
                                                        (change)="
                                                            changeKonfigKontingentierung(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            'KONTINGENTIERUNG'
                                                                | translate
                                                        }}</mat-checkbox
                                                    >
                                                </div>

                                                <!-- Keinen Gutschein für Sozialhilfeempfänger ausstellen -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKeineGutscheineFuerSozialhilfeEmpfaenger
                                                        )
                                                    "
                                                >
                                                    <mat-checkbox
                                                        id="keineGutscheineFuerSozialhilfeEmpfaenger_id_{{
                                                            i
                                                        }}"
                                                        name="keineGutscheineFuerSozialhilfeEmpfaenger_id_{{
                                                            i
                                                        }}"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKeineGutscheineFuerSozialhilfeEmpfaenger
                                                        "
                                                        (change)="
                                                            changeKonfigKeineGutscheineFuerSozialhilfeEmpfaenger(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            'GEMEINDE_KEIN_GUTSCHEIN_FUER_SOZIALHILFE_EMPFAENGER'
                                                                | translate
                                                        }}</mat-checkbox
                                                    >
                                                </div>

                                                <!-- Custom Erwerbspensum Zuschlag -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ].isAnspruchUnabhaengingVonBeschaeftigungsPensum()
                                                        ) &&
                                                        !konfigurationsListe[
                                                            i
                                                        ].isAnspruchUnabhaengingVonBeschaeftigungsPensum() &&
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                >
                                                    <mat-checkbox
                                                        id="ewp_zuschlag_override_id_{{
                                                            i
                                                        }}"
                                                        name="ewp_zuschlag_override_id_{{
                                                            i
                                                        }}"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlagOverriden
                                                        "
                                                        (change)="
                                                            changeKonfigErwerbspensumZuschlagOverriden(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            *ngIf="
                                                                !konfigurationsListe[
                                                                    i
                                                                ].isTextForFKJV
                                                            "
                                                            translate
                                                            [translateParams]="{
                                                                valueMandant:
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .erwerbspensumZuschlagMax
                                                            }"
                                                        >
                                                            EWP_ZUSCHLAG_OVERRIDE
                                                        </span>
                                                        <span
                                                            *ngIf="
                                                                konfigurationsListe[
                                                                    i
                                                                ].isTextForFKJV
                                                            "
                                                            translate
                                                            [translateParams]="{
                                                                valueMandant:
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .erwerbspensumZuschlagMax
                                                            }"
                                                        >
                                                            EWP_ZUSCHLAG_OVERRIDE_FKJV
                                                        </span>
                                                    </mat-checkbox>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlag
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .erwerbspensumZuschlagOverriden
                                                    "
                                                >
                                                    <input
                                                        id="ewp_zuschlag_id_{{
                                                            i
                                                        }}"
                                                        name="ewp_zuschlag_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                        type="number"
                                                        #ewp_zuschlag="ngModel"
                                                        required
                                                        customMinMax
                                                        [minValue]="0"
                                                        [maxValue]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlagMax
                                                        "
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlag
                                                        "
                                                        (change)="
                                                            changeErwerbspensumZuschlag(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    />

                                                    <dv-error-messages
                                                        inputId="ewp_zuschlag_id_{{
                                                            i
                                                        }}"
                                                        [errorObject]="
                                                            ewp_zuschlag.errors
                                                        "
                                                    >
                                                    </dv-error-messages>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Gemeindeeinstellungen. -->
                                        <div
                                            *ngIf="
                                                isSuperAdmin() &&
                                                isKonfigurationEditable(
                                                    konfigurationsListe[i]
                                                )
                                            "
                                        >
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <!--                                                            Custom Minimale Erwerbspensen -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMinimumOverriden
                                                            )
                                                        "
                                                    >
                                                        <mat-checkbox
                                                            id="ewp_mininum_override_id_{{
                                                                i
                                                            }}"
                                                            name="ewp_mininum_override_id_{{
                                                                i
                                                            }}"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMinimumOverriden
                                                            "
                                                            (change)="
                                                                changeKonfigErwerbspensumMinimumOverriden(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                translate
                                                                [translateParams]="{
                                                                    valueMandant:
                                                                        konfigurationsListe[
                                                                            i
                                                                        ]
                                                                            .erwerbspensumMiminumVorschuleMax
                                                                }"
                                                            >
                                                                EWP_MINIUM_OVERRIDE
                                                            </span>
                                                        </mat-checkbox>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumMinimumOverriden
                                                        "
                                                    >
                                                        <label
                                                            for="ewp_mininum_vorschulalter_id{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            [translateParams]="{
                                                                valueMandant:
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .erwerbspensumMiminumVorschuleMax
                                                            }"
                                                        >
                                                            EWP_MINIUM_VORSCHULKINDER_OVERRIDE</label
                                                        >
                                                        <input
                                                            id="ewp_mininum_vorschulalter_id{{
                                                                i
                                                            }}"
                                                            name="ewp_mininum_vorschulalter_id{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #ewp_mininum_vorschulalter="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [maxValue]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumVorschuleMax
                                                            "
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumVorschule
                                                            "
                                                            (change)="
                                                                changeErwerbspensumMinimumVorschule(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="ewp_mininum_vorschulalter_id{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                ewp_mininum_vorschulalter.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumMinimumOverriden
                                                        "
                                                    >
                                                        <label
                                                            for="ewp_mininum_schulkind_id{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            [translateParams]="{
                                                                valueMandant:
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .erwerbspensumMiminumSchulkinderMax
                                                            }"
                                                        >
                                                            EWP_MINIUM_SCHULKINDER_OVERRIDE</label
                                                        >
                                                        <input
                                                            id="ewp_mininum_schulkind_id{{
                                                                i
                                                            }}"
                                                            name="ewp_mininum_schulkind_id{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #ewp_mininum_schulkind="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [maxValue]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumSchulkinderMax
                                                            "
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumSchulkinder
                                                            "
                                                            (change)="
                                                                changeErwerbspensumMinimumSchulkinder(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="ewp_mininum_schulkind_id{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                ewp_mininum_schulkind.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!--                                            Zusätzliche Vergünstigung durch die Gemeinde-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinEnabled
                                                            )
                                                        "
                                                    >
                                                        <mat-checkbox
                                                            id="zusaetzliche_verguenstigung_{{
                                                                i
                                                            }}"
                                                            name="zusaetzliche_verguenstigung_{{
                                                                i
                                                            }}"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinEnabled
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherGutscheinEnabled(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        >
                                                            <span translate>
                                                                ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE
                                                            </span>
                                                        </mat-checkbox>
                                                    </div>

                                                    <!--                                            Zusätzliche Vergünstigung durch die Gemeinde-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinTyp
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        "
                                                    >
                                                        <mat-button-toggle-group
                                                            name="konfigZusaetzlicherGutscheinTyp"
                                                            aria-label="Zusätzlicher Gutschein Typ"
                                                            [value]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinTyp
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherGutscheinTyp(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ],
                                                                    $event
                                                                )
                                                            "
                                                        >
                                                            <mat-button-toggle
                                                                [value]="
                                                                    TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                                "
                                                                [checked]="
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinTyp ===
                                                                    TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                                "
                                                            >
                                                                {{
                                                                    'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_TYP_PAUSCHAL'
                                                                        | translate
                                                                }}
                                                            </mat-button-toggle>
                                                            <mat-button-toggle
                                                                [value]="
                                                                    TSGemeindeZusaetzlicherGutscheinTyp.LINEAR
                                                                "
                                                                [checked]="
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinTyp ===
                                                                    TSGemeindeZusaetzlicherGutscheinTyp.LINEAR
                                                                "
                                                            >
                                                                {{
                                                                    'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_TYP_LINEAR'
                                                                        | translate
                                                                }}
                                                            </mat-button-toggle>
                                                        </mat-button-toggle-group>
                                                    </div>
                                                    <!--                                            Zusätzlicher Gutschein in CHF pro Tag bei Kitas-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragKita
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_KITA_VALUE</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_gutschein_betrag_kita="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragKita
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherGutscheinBetragKita(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_betrag_kita.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!--                                            Zusätzlicher Gutschein in CHF pro Stunde bei TFOs-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragTfo
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_TFO_VALUE</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_gutschein_betrag_tfo="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragTfo
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherGutscheinBetragTfo(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_betrag_tfo.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!--                                            Max Zusätzlicher Gutschein in CHF pro Tag bei Kitas-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragKita
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.LINEAR
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_max_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_LINEAR_MAX_KITA_VALUE</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_max_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_max_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_gutschein_max_betrag_kita="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragKita
                                                            "
                                                            (change)="
                                                                changeKonfig(
                                                                    TSEinstellungKey.GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_KITA_MAX,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinLinearMaxBetragKita,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_max_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_max_betrag_kita.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>
                                                    <!--                                            Max Zusätzlicher Gutschein in CHF pro Stunde bei TFOs-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragTfo
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.LINEAR
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_max_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_LINEAR_MAX_TFO_VALUE</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_max_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_max_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_gutschein_max_betrag_tfo="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragTfo
                                                            "
                                                            (change)="
                                                                changeKonfig(
                                                                    TSEinstellungKey.GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_LINEAR_TFO_MAX,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinLinearMaxBetragTfo,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_max_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_max_betrag_tfo.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!--                                            Zusaetzlicher Gutschein bei Kitas anbieten bis und mit-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeKita
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        "
                                                    >
                                                        <label
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHER_GUTSCHEIN_KITA_BIS_UND_MIT</label
                                                        >
                                                        <mat-select
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeKita
                                                            "
                                                            (selectionChange)="
                                                                changeKonfigZusaetzlicherGutscheinBisUndMitSchulstufeKita(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                            id="zusaetzlicher_gutschein_kita_bis_und_mit_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_kita_bis_und_mit_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                        >
                                                            <mat-option
                                                                *ngFor="
                                                                    let etv of einschulungTypGemeindeValues
                                                                "
                                                                [value]="etv"
                                                                >{{
                                                                    etv
                                                                        | translate
                                                                }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                    <!-- Zusaetzlicher Gutschein bei TFOs anbieten bis und mit-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeTfo
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        "
                                                    >
                                                        <label
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHER_GUTSCHEIN_TFO_BIS_UND_MIT</label
                                                        >
                                                        <mat-select
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeTfo
                                                            "
                                                            (selectionChange)="
                                                                changeKonfigZusaetzlicherGutscheinBisUndMitSchulstufeTfo(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                            id="zusaetzlicher_gutschein_tfo_bis_und_mit_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_tfo_bis_und_mit_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                        >
                                                            <mat-option
                                                                *ngFor="
                                                                    let etv of einschulungTypGemeindeValues
                                                                "
                                                                [value]="etv"
                                                                >{{
                                                                    etv
                                                                        | translate
                                                                }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                    <!-- Zusaetzlicher Gutschein Minimales Massgebendes Einkommen -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMinMassgebendesEinkommen
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_min_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_MIN_MASSGEBENDES_EINKOMMEN</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_min_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_min_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            required
                                                            #zusaetzlicher_gutschein_min_massgebendes_einkommen="ngModel"
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMinMassgebendesEinkommen
                                                            "
                                                            (change)="
                                                                changeKonfig(
                                                                    TSEinstellungKey.GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MIN_MASSGEBENDES_EINKOMMEN,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinMinMassgebendesEinkommen,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />
                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_min_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_min_massgebendes_einkommen.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>
                                                    <!-- Zusaetzlicher Gutschein Minimales Massgebendes Einkommen -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMaxMassgebendesEinkommen
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_max_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_MAX_MASSGEBENDES_EINKOMMEN</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_gutschein_max_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_gutschein_max_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            required
                                                            #zusaetzlicher_gutschein_max_massgebendes_einkommen="ngModel"
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMaxMassgebendesEinkommen
                                                            "
                                                            (change)="
                                                                changeKonfig(
                                                                    TSEinstellungKey.GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_MAX_MASSGEBENDES_EINKOMMEN,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinMaxMassgebendesEinkommen,
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />
                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_gutschein_max_massgebendes_einkommen_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_gutschein_max_massgebendes_einkommen.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!-- Zusätzliche Vergünstigung durch die Gemeinde bei Kindern bis 12 Monate-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragEnabled
                                                            )
                                                        "
                                                    >
                                                        <mat-checkbox
                                                            id="zusaetzlicher_babybeitrag_gemeinde_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_babybeitrag_gemeinde_{{
                                                                i
                                                            }}"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragEnabled
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherBabybeitragEnabled(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                >{{
                                                                    'ZUSAETZLICHER_BABYBEITRAG_GEMEINDE'
                                                                        | translate
                                                                            : {
                                                                                  monate: getDauerBabytarif(
                                                                                      konf.gesuchsperiode
                                                                                  )
                                                                              }
                                                                }}
                                                            </span>
                                                        </mat-checkbox>
                                                    </div>
                                                    <!-- Zusätzlicher maximaler Gutschein in CHF pro Tag bei Kitas-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragKita
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherBabybeitragEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_gutschein_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHER_BABYBEITRAG_BETRAG_KITA</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_babybeitrag_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_babybeitrag_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_babybeitrag_betrag_kita="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragKita
                                                            "
                                                            (change)="
                                                                changeZusaetzlicherBabybeitragKita(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_babybeitrag_betrag_kita_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_babybeitrag_betrag_kita.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>
                                                    <!--                                            Zusätzlicher maximaler Gutschein in CHF pro Tag bei TFOs-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragTfo
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherBabybeitragEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_babybeitrag_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHER_BABYBEITRAG_BETRAG_TFO</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_babybeitrag_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_babybeitrag_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_babybeitrag_betrag_tfo="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragTfo
                                                            "
                                                            (change)="
                                                                changeZusaetzlicherBabybeitragTfo(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_babybeitrag_betrag_tfo_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_babybeitrag_betrag_tfo.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!--                                            Zusätzlicher Anspruch durch die Gemeinde bei Freiwilligenarbeit-->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled
                                                            )
                                                        "
                                                    >
                                                        <mat-checkbox
                                                            id="zusaetzlicher_anspruch_freiwilligenarbeit_enabled_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_anspruch_freiwilligenarbeit_enabled_{{
                                                                i
                                                            }}"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        >
                                                            <span translate>
                                                                ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT
                                                            </span>
                                                        </mat-checkbox>
                                                    </div>
                                                    <!--                                            Maximal zugelassener Anspruch % Freiwilligenarbeit -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="zusaetzlicher_anspruch_freiwilligenarbeit_max_id_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_MAX</label
                                                        >
                                                        <input
                                                            id="zusaetzlicher_anspruch_freiwilligenarbeit_max_id_{{
                                                                i
                                                            }}"
                                                            name="zusaetzlicher_anspruch_freiwilligenarbeit_max_id_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #zusaetzlicher_anspruch_freiwilligenarbeit_max="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [maxValue]="100"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherAnspruchFreiwilligenarbeitMaxprozent
                                                            "
                                                            (change)="
                                                                changeKonfigZusaetzlicherAnspruchFreiwilligenarbeitMax(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="zusaetzlicher_anspruch_freiwilligenarbeit_max_id_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                zusaetzlicher_anspruch_freiwilligenarbeit_max.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>

                                                    <!-- Mahlzeitenverguenstigung -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigMahlzeitenverguenstigungEnabled
                                                            )
                                                        "
                                                    >
                                                        <mat-checkbox
                                                            id="mahlzeitenverguenstigung_enabled_{{
                                                                i
                                                            }}"
                                                            name="mahlzeitenverguenstigung_enabled_{{
                                                                i
                                                            }}"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigMahlzeitenverguenstigungEnabled
                                                            "
                                                            (change)="
                                                                changeKonfigMahlzeitenverguenstigungEnabled(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        >
                                                            <span translate>
                                                                MAHLZEITENVERGUENSTIGUNG_DURCH_GEMEINDE
                                                            </span>
                                                        </mat-checkbox>
                                                    </div>

                                                    <!-- Minimaler Elternbeitrag Mahlzeiten -->
                                                    <div
                                                        class="form-group"
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigMahlzeitenverguenstigungEnabled
                                                        "
                                                    >
                                                        <label
                                                            for="minimaler_elternbeitrag_mahlzeiten_{{
                                                                i
                                                            }}"
                                                            class="required"
                                                            translate
                                                            >MINIMALER_ELTERNBEITRAG_MAHLZEITEN</label
                                                        >
                                                        <input
                                                            id="minimaler_elternbeitrag_mahlzeiten_{{
                                                                i
                                                            }}"
                                                            name="minimaler_elternbeitrag_mahlzeiten_{{
                                                                i
                                                            }}"
                                                            class="form-control"
                                                            type="number"
                                                            #minimaler_elternbeitrag_mahlzeiten="ngModel"
                                                            required
                                                            customMinMax
                                                            [minValue]="0"
                                                            [(ngModel)]="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigMahlzeitenverguenstigungMinimalerElternbeitragMahlzeit
                                                            "
                                                            (change)="
                                                                changeKonfigMahlzeitenverguenstigungMinmalerElternanteilMahlzeit(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                )
                                                            "
                                                        />

                                                        <dv-error-messages
                                                            inputId="minimaler_elternbeitrag_mahlzeiten_{{
                                                                i
                                                            }}"
                                                            [errorObject]="
                                                                minimaler_elternbeitrag_mahlzeiten.errors
                                                            "
                                                        >
                                                        </dv-error-messages>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <!--                                            Mahlzeitenverguenstigung pro Einkommensstufe-->
                                                        <div
                                                            class="table-responsive mt-2"
                                                            *ngIf="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigMahlzeitenverguenstigungEnabled
                                                            "
                                                        >
                                                            <table
                                                                class="mahlzeitenverguenstigung-table"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            translate
                                                                        >
                                                                            MAHLZEITENVERGUENSTIGUNG_MASSGEBENDES_EINKOMMEN_MIN
                                                                        </th>
                                                                        <th
                                                                            translate
                                                                        >
                                                                            MAHLZEITENVERGUENSTIGUNG_MASSGEBENDES_EINKOMMEN_MAX
                                                                        </th>
                                                                        <th
                                                                            class="align-right"
                                                                            translate
                                                                        >
                                                                            VERGUENSTIGUNG_PRO_MAHLZEIT
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe1_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe1_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    [value]="
                                                                                        'MAHLZEITENVERGUENSTIGUNG_NULL_EINKOMMEN'
                                                                                            | translate
                                                                                    "
                                                                                    disabled
                                                                                />
                                                                                <dv-error-messages></dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe1_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe1_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    #mahlzeitenverguenstigung_stufe1_max="ngModel"
                                                                                    required
                                                                                    customMinMax
                                                                                    [minValue]="
                                                                                        1
                                                                                    "
                                                                                    [(ngModel)]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen
                                                                                    "
                                                                                    (keyup)="
                                                                                        changeKonfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen(
                                                                                            konfigurationsListe[
                                                                                                i
                                                                                            ],
                                                                                            i
                                                                                        )
                                                                                    "
                                                                                />

                                                                                <dv-error-messages
                                                                                    inputId="mahlzeitenverguenstigung_stufe1_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    [errorObject]="
                                                                                        mahlzeitenverguenstigung_stufe1_max.errors
                                                                                    "
                                                                                >
                                                                                </dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe1_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe1_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    #mahlzeitenverguenstigung_stufe1_mahlzeit="ngModel"
                                                                                    required
                                                                                    customMinMax
                                                                                    [minValue]="
                                                                                        0
                                                                                    "
                                                                                    [(ngModel)]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe1VerguenstigungMahlzeit
                                                                                    "
                                                                                    (change)="
                                                                                        changeKonfigMahlzeitenverguenstigungEinkommensstufe1VerguenstigungMahlzeit(
                                                                                            konfigurationsListe[
                                                                                                i
                                                                                            ]
                                                                                        )
                                                                                    "
                                                                                />

                                                                                <dv-error-messages
                                                                                    inputId="mahlzeitenverguenstigung_stufe1_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    [errorObject]="
                                                                                        mahlzeitenverguenstigung_stufe1_mahlzeit.errors
                                                                                    "
                                                                                >
                                                                                </dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe2_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe2_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    disabled
                                                                                    [value]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen +
                                                                                        1
                                                                                    "
                                                                                />
                                                                                <dv-error-messages></dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe2_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe2_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    #mahlzeitenverguenstigung_stufe2_max="ngModel"
                                                                                    required
                                                                                    customMinMax
                                                                                    [minValue]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen +
                                                                                        2
                                                                                    "
                                                                                    [(ngModel)]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe2MaxEinkommen
                                                                                    "
                                                                                    (change)="
                                                                                        changeKonfigMahlzeitenverguenstigungEinkommensstufe2MaxEinkommen(
                                                                                            konfigurationsListe[
                                                                                                i
                                                                                            ]
                                                                                        )
                                                                                    "
                                                                                />

                                                                                <dv-error-messages
                                                                                    inputId="mahlzeitenverguenstigung_stufe2_max_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    [errorObject]="
                                                                                        mahlzeitenverguenstigung_stufe2_max.errors
                                                                                    "
                                                                                >
                                                                                </dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe2_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe2_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    #mahlzeitenverguenstigung_stufe2_mahlzeit="ngModel"
                                                                                    required
                                                                                    customMinMax
                                                                                    [minValue]="
                                                                                        0
                                                                                    "
                                                                                    [(ngModel)]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe2VerguenstigungMahlzeit
                                                                                    "
                                                                                    (change)="
                                                                                        changeKonfigMahlzeitenverguenstigungEinkommensstufe2VerguenstigungMahlzeit(
                                                                                            konfigurationsListe[
                                                                                                i
                                                                                            ]
                                                                                        )
                                                                                    "
                                                                                />

                                                                                <dv-error-messages
                                                                                    inputId="mahlzeitenverguenstigung_stufe2_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    [errorObject]="
                                                                                        mahlzeitenverguenstigung_stufe2_mahlzeit.errors
                                                                                    "
                                                                                >
                                                                                </dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe3_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe3_min_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    [value]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe2MaxEinkommen +
                                                                                        1
                                                                                    "
                                                                                    disabled
                                                                                />
                                                                                <dv-error-messages></dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td>
                                                                            <div
                                                                                class="form-group"
                                                                            >
                                                                                <input
                                                                                    id="mahlzeitenverguenstigung_stufe3_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    name="mahlzeitenverguenstigung_stufe3_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    class="form-control"
                                                                                    type="number"
                                                                                    #mahlzeitenverguenstigung_stufe3_mahlzeit="ngModel"
                                                                                    required
                                                                                    customMinMax
                                                                                    [minValue]="
                                                                                        0
                                                                                    "
                                                                                    [(ngModel)]="
                                                                                        konfigurationsListe[
                                                                                            i
                                                                                        ]
                                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe3VerguenstigungMahlzeit
                                                                                    "
                                                                                    (change)="
                                                                                        changeKonfigMahlzeitenverguenstigungEinkommensstufe3VerguenstigungMahlzeit(
                                                                                            konfigurationsListe[
                                                                                                i
                                                                                            ]
                                                                                        )
                                                                                    "
                                                                                />

                                                                                <dv-error-messages
                                                                                    inputId="mahlzeitenverguenstigung_stufe3_mahlzeit_id_{{
                                                                                        i
                                                                                    }}"
                                                                                    [errorObject]="
                                                                                        mahlzeitenverguenstigung_stufe3_mahlzeit.errors
                                                                                    "
                                                                                >
                                                                                </dv-error-messages>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <!--Mahlzeitenverguenstigung auch für Sozialhilfeempfänger-->
                                                        <div
                                                            class="form-group"
                                                            *ngIf="
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigMahlzeitenverguenstigungEnabled
                                                            "
                                                        >
                                                            <mat-checkbox
                                                                id="mahlzeitenverguenstigung_sozialhilfeempfaenger_enabled_{{
                                                                    i
                                                                }}"
                                                                name="mahlzeitenverguenstigung_sozialhilfeempfaenger_enabled_{{
                                                                    i
                                                                }}"
                                                                [(ngModel)]="
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigMahlzeitenverguenstigungFuerSozialhilfebezuegerEnabled
                                                                "
                                                                (change)="
                                                                    changeKonfigMahlzeitenverguenstigungFuerSozialhilfebezuegerEnabled(
                                                                        konfigurationsListe[
                                                                            i
                                                                        ]
                                                                    )
                                                                "
                                                            >
                                                                <span translate>
                                                                    MAHLZEITENVERGUENSTIGUNG_AUCH_FUER_SOZIALHILFEEMPFAENGER
                                                                </span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            *ngIf="
                                                !isUndefined(
                                                    konfigurationsListe[i]
                                                        .konfigHoheEinkommensklassenAktiviert
                                                ) &&
                                                isKonfigurationEditable(
                                                    konfigurationsListe[i]
                                                ) &&
                                                (konfigurationsListe[i]
                                                    .konfigHoheEinkommensklassenAktiviert ||
                                                    isSuperAdmin())
                                            "
                                        >
                                            <div class="col-sm-6">
                                                <!-- Höhere Einkommensklassen aktivieren -->
                                                <div class="form-group">
                                                    <mat-checkbox
                                                        id="hohe_einkommensklassen_aktiviert_{{
                                                            i
                                                        }}"
                                                        name="hohe_einkommensklassen_aktiviert_{{
                                                            i
                                                        }}"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigHoheEinkommensklassenAktiviert
                                                        "
                                                        (change)="
                                                            changeKonfigHoheEinkommensklassenAktiviert(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            >{{
                                                                'GEMEINDE_HOHE_EINKOMMENSKLASSEN'
                                                                    | translate
                                                            }}
                                                        </span>
                                                    </mat-checkbox>
                                                </div>
                                                <!-- Höhere Einkommensklassen Betrag pro Tag-->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        konfigurationsListe[i]
                                                            .konfigHoheEinkommensklassenAktiviert
                                                    "
                                                >
                                                    <label
                                                        for="hohe_einkommensklassen_betrag_kita_id_{{
                                                            i
                                                        }}"
                                                        class="required"
                                                        translate
                                                        >GEMEINDE_HOHE_EINKOMMENSKLASSEN_BETRAG_KITA</label
                                                    >
                                                    <input
                                                        id="hohe_einkommensklassen_betrag_kita_id_{{
                                                            i
                                                        }}"
                                                        name="hohe_einkommensklassen_betrag_kita_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                        type="number"
                                                        #hohe_einkommensklassen_betrag_kita="ngModel"
                                                        required
                                                        customMinMax
                                                        [minValue]="0"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigHoheEinkommensklassenBetragKita
                                                        "
                                                        (change)="
                                                            changeKonfigHoheEinkommensklassenBetragKita(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    />

                                                    <dv-error-messages
                                                        inputId="hohe_einkommensklassen_betrag_kita_id_{{
                                                            i
                                                        }}"
                                                        [errorObject]="
                                                            hohe_einkommensklassen_betrag_kita.errors
                                                        "
                                                    >
                                                    </dv-error-messages>
                                                </div>
                                                <!-- Höhere Einkommensklassen Betrag TFO pro Stunde-->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        konfigurationsListe[i]
                                                            .konfigHoheEinkommensklassenAktiviert
                                                    "
                                                >
                                                    <label
                                                        for="hohe_einkommensklassen_betrag_tfo_id_{{
                                                            i
                                                        }}"
                                                        class="required"
                                                        translate
                                                        >GEMEINDE_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO</label
                                                    >
                                                    <input
                                                        id="hohe_einkommensklassen_betrag_tfo_id_{{
                                                            i
                                                        }}"
                                                        name="hohe_einkommensklassen_betrag_tfo_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                        type="number"
                                                        #hohe_einkommensklassen_betrag_tfo="ngModel"
                                                        required
                                                        customMinMax
                                                        [minValue]="0"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigHoheEinkommensklassenBetragTfo
                                                        "
                                                        (change)="
                                                            changeKonfigHoheEinkommensklassenBetragTfo(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    />

                                                    <dv-error-messages
                                                        inputId="hohe_einkommensklassen_betrag_tfo_id_{{
                                                            i
                                                        }}"
                                                        [errorObject]="
                                                            hohe_einkommensklassen_betrag_tfo.errors
                                                        "
                                                    >
                                                    </dv-error-messages>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        konfigurationsListe[i]
                                                            .konfigHoheEinkommensklassenAktiviert
                                                    "
                                                >
                                                    <label
                                                        for="hohe_einkommensklassen_betrag_tfo_ab_primarschule_id_{{
                                                            i
                                                        }}"
                                                        class="required"
                                                        translate
                                                        >GEMEINDE_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO_AB_PRIMARSCHULE</label
                                                    >
                                                    <input
                                                        id="hohe_einkommensklassen_betrag_tfo_ab_primarschule_id_{{
                                                            i
                                                        }}"
                                                        name="hohe_einkommensklassen_betrag_tfo_ab_primarschule_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                        type="number"
                                                        #hohe_einkommensklassen_betrag_tfo_ab_primarschule="ngModel"
                                                        required
                                                        customMinMax
                                                        [minValue]="0"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigHoheEinkommensklassenBetragTfoAbPrimarschule
                                                        "
                                                        (change)="
                                                            changeKonfigHoheEinkommensklassenBetragTfoAbPrimarschule(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    />

                                                    <dv-error-messages
                                                        inputId="hohe_einkommensklassen_betrag_tfo_ab_primarschule_id_{{
                                                            i
                                                        }}"
                                                        [errorObject]="
                                                            hohe_einkommensklassen_betrag_tfo_ab_primarschule.errors
                                                        "
                                                    >
                                                    </dv-error-messages>
                                                </div>
                                                <!-- Höhere Einkommensklassen bis Massgebendeneinkommen -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="
                                                        konfigurationsListe[i]
                                                            .konfigHoheEinkommensklassenAktiviert
                                                    "
                                                >
                                                    <label
                                                        for="hohe_einkommensklassen_massgebendeneinkommen_id_{{
                                                            i
                                                        }}"
                                                        class="required"
                                                        translate
                                                        >GEMEINDE_HOHE_EINKOMMENSKLASSEN_MASSGEBENDENEINKOMMEN</label
                                                    >
                                                    <input
                                                        id="hohe_einkommensklassen_massgebendeneinkommen_id_{{
                                                            i
                                                        }}"
                                                        name="hohe_einkommensklassen_massgebendeneinkommen_id_{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                        type="number"
                                                        #hohe_einkommensklassen_massgebendeneinkommen="ngModel"
                                                        required
                                                        customMinMax
                                                        [minValue]="0"
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigHoheEinkommensklassenMassgebendenEinkommen
                                                        "
                                                        (change)="
                                                            changeKonfigHoheEinkommensklassenMassgebendenEinkommen(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                    />

                                                    <dv-error-messages
                                                        inputId="hohe_einkommensklassen_massgebendeneinkommen_id_{{
                                                            i
                                                        }}"
                                                        [errorObject]="
                                                            hohe_einkommensklassen_massgebendeneinkommen.errors
                                                        "
                                                    >
                                                    </dv-error-messages>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <!-- Abhängikeit Anspruch und Beschäftigungspensum -->
                                                <div
                                                    class="form-group"
                                                    *ngIf="isSuperAdmin()"
                                                >
                                                    <label
                                                        for="anspruch_unabhaenging_von_beschaeftigungs_pensum{{
                                                            i
                                                        }}"
                                                        class="required"
                                                        translate
                                                        >ABHAENGIGKEIT_ANSPRUCH_BESCHAEFTIGUNGPENSUM
                                                        <dv-tooltip
                                                            id="anspruch_unabhaenging_von_beschaeftigungs_pensum-tooltip"
                                                            [text]="
                                                                'ABHAENGIGKEIT_ANSPRUCH_BESCHAEFTIGUNGPENSUM_HELP'
                                                                    | translate
                                                            "
                                                        ></dv-tooltip>
                                                    </label>
                                                    <mat-select
                                                        [(ngModel)]="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .anspruchUnabhaengingVonBeschaeftigungsPensum
                                                        "
                                                        (selectionChange)="
                                                            changeKonfigAbhaengigkeitAnspruchBeschaeftigung(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                            )
                                                        "
                                                        id="anspruch_unabhaenging_von_beschaeftigungs_pensum{{
                                                            i
                                                        }}"
                                                        name="anspruch_unabhaenging_von_beschaeftigungs_pensum{{
                                                            i
                                                        }}"
                                                        class="form-control"
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let value of anspruchBeschaeftigungAbhaengigkeitTypValues
                                                            "
                                                            [value]="value"
                                                            >{{
                                                                value
                                                                    | translate
                                                            }}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            *ngIf="
                                                showGemeindespezifischeKonfigForBG(
                                                    konfigurationsListe[i]
                                                        .gesuchsperiode
                                                ) &&
                                                isKonfigurationEditable(
                                                    konfigurationsListe[i]
                                                )
                                            "
                                        >
                                            <div class="col-sm-6">
                                                <strong
                                                    >{{
                                                        'GEMEINDESPEZIFISCHE_BG_KONFIGURATIONEN_TITLE'
                                                            | translate
                                                    }}
                                                    :</strong
                                                >
                                                <!-- Gemeindespezifische Konfurationen für BG -->
                                                <div class="margin-top-20">
                                                    <ng-container
                                                        *ngFor="
                                                            let config of konfigurationsListe[
                                                                i
                                                            ]
                                                                .gemeindespezifischeBGKonfigurationen;
                                                            let j = index
                                                        "
                                                    >
                                                        <div class="form-group">
                                                            <label
                                                                for="konfig_{{
                                                                    config.key
                                                                }}"
                                                                class="required"
                                                                translate
                                                                >{{
                                                                    config.key
                                                                }}</label
                                                            >
                                                            <input
                                                                id="konfig_{{
                                                                    config.key
                                                                }}"
                                                                type="text"
                                                                required
                                                                name="konfig_{{
                                                                    config.value
                                                                }}"
                                                                #gemeindespezifische_bg_konfig="ngModel"
                                                                [(ngModel)]="
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .gemeindespezifischeBGKonfigurationen[
                                                                        j
                                                                    ].value
                                                                "
                                                                class="form-control"
                                                            />
                                                            <dv-error-messages
                                                                [errorObject]="
                                                                    gemeindespezifische_bg_konfig.errors
                                                                "
                                                                inputId="konfig_{{
                                                                    config.key
                                                                }}"
                                                            ></dv-error-messages>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab-body>
                            </dv-accordion-tab>
                        </ng-container>
                    </dv-accordion>
                </div>
            </div>
        </fieldset>
    </div>

    <ng-template #viewModeTemplate>
        <fieldset>
            <div class="row" *ngIf="!stammdaten.gutscheinSelberAusgestellt">
                <div class="col-sm-6 mt-2">
                    <p>
                        {{ 'GUTSCHEINAUSGABESTELLE' | translate }}:
                        {{ stammdaten.gemeindeAusgabestelle.name }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <!-- BANKVERBINDUNG -->
                    <div class="form-group">
                        <h3 translate>BANKVERBINDUNG</h3>
                        <p>{{ stammdaten.kontoinhaber }}</p>
                        <p>{{ stammdaten.bic }}</p>
                        <p>{{ stammdaten.iban }}</p>
                    </div>

                    <!--            Benachrichtigungen bei Verfügungen automatisch per Email senden-->
                    <div class="mt-5">
                        <h3 *ngIf="stammdaten.benachrichtigungBgEmailAuto">
                            {{
                                'BENACHRICHTIGUNGEN_EMAIL_SENDEN_JA' | translate
                            }}
                        </h3>
                        <h3 *ngIf="!stammdaten.benachrichtigungBgEmailAuto">
                            {{
                                'BENACHRICHTIGUNGEN_EMAIL_SENDEN_NEIN'
                                    | translate
                            }}
                        </h3>
                        <p></p>
                    </div>
                    <!--            standard Fusszeile bei Verfügungen verwenden-->
                    <div>
                        <div>
                            <div *ngIf="stammdaten.standardDokSignature">
                                <h3>
                                    {{
                                        'STANDARD_DOK_SIGNATURE_JA' | translate
                                    }}
                                </h3>
                            </div>
                            <div
                                *ngIf="!stammdaten.standardDokSignature"
                                class="form-group"
                            >
                                <div class="row">
                                    <div class="col-sm-6">
                                        <h3>
                                            {{
                                                'STANDARD_DOK_SIGNATURE_NEIN'
                                                    | translate
                                            }}
                                        </h3>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <p>
                                        <b>{{ stammdaten.standardDokTitle }}</b>
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p>
                                                {{
                                                    stammdaten.standardDokUnterschriftTitel
                                                }}
                                            </p>
                                        </div>
                                        <div class="col-sm-6">
                                            <p>
                                                {{
                                                    stammdaten.standardDokUnterschriftTitel2
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p>
                                                {{
                                                    stammdaten.standardDokUnterschriftName
                                                }}
                                            </p>
                                        </div>
                                        <div class="col-sm-6">
                                            <p>
                                                {{
                                                    stammdaten.standardDokUnterschriftName2
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--            Verantwortlicher Tagesschule per Email informieren, nachdem ein Mischgesuch verfügt wurde. (wird nur angezeigt wenn Mischgesuche möglich sind) -->
                    <div
                        *ngIf="
                            stammdaten.gemeinde.angebotTS &&
                            stammdaten.tsVerantwortlicherNachVerfuegungBenachrichtigen
                        "
                    >
                        <div>
                            <h3>
                                {{
                                    'TS_VERANTWORTLICHER_NACH_VERFUEGUNG_BENACHRICHTIGEN_JA'
                                        | translate
                                }}
                            </h3>
                        </div>
                    </div>
                    <!--Antragsteller bei Öffnung der Gesuchsperiode per Email informieren-->
                    <div>
                        <div>
                            <h3
                                *ngIf="
                                    stammdaten.emailBeiGesuchsperiodeOeffnung
                                "
                            >
                                {{
                                    'EMAIL_BEI_GESUCHSPERIODE_OEFFNUNG_JA'
                                        | translate
                                }}
                            </h3>
                            <h3
                                *ngIf="
                                    !stammdaten.emailBeiGesuchsperiodeOeffnung
                                "
                            >
                                {{
                                    'EMAIL_BEI_GESUCHSPERIODE_OEFFNUNG_NEIN'
                                        | translate
                                }}
                            </h3>
                        </div>
                    </div>
                    <!-- Zusätzlicher Text auf der Verfügung -->
                    <div>
                        <div>
                            <h3 *ngIf="stammdaten.hasZusatzTextVerfuegung">
                                {{
                                    'ZUSAETZLICHER_TEXT_VERFUEGUNG_JA'
                                        | translate
                                }}
                            </h3>
                        </div>
                    </div>
                    <!-- Betreuungsgutscheine anbieten ab -->
                    <div class="mt-5">
                        <div class="form-group">
                            <h3 translate>BEGU_START</h3>
                            <p>{{ beguStartStr }}</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <!-- Standardverantwortliche -->
                    <div *ngIf="stammdaten.defaultBenutzerBG">
                        <div class="form-group">
                            <h3 translate>ALT_STANDARD_VERANTWORTLICHE</h3>
                            <p>
                                {{ stammdaten.defaultBenutzerBG.vorname }}
                                {{ stammdaten.defaultBenutzerBG.nachname }}
                            </p>
                        </div>
                    </div>

                    <!-- Alternative Kontaktadresse -->
                    <div>
                        <div *ngIf="!altBGAdresse; else displayBGAdresse">
                            <div class="form-group">
                                <h3 translate>NO_ALTERNATIVE_ADRESSE</h3>
                            </div>
                        </div>
                        <ng-template #displayBGAdresse>
                            <div>
                                <div class="form-group">
                                    <h3 translate>ALTERNATIVE_ADRESSE</h3>
                                    <p>
                                        {{ stammdaten.bgAdresse.organisation }}
                                    </p>
                                    <p>
                                        {{ stammdaten.bgAdresse.strasse }}
                                        {{ stammdaten.bgAdresse.hausnummer }}
                                    </p>
                                    <p>
                                        {{ stammdaten.bgAdresse.plz }}
                                        {{ stammdaten.bgAdresse.ort }}
                                    </p>
                                    <p *ngIf="stammdaten.bgEmail">
                                        {{ stammdaten.bgEmail }}
                                    </p>
                                    <p *ngIf="stammdaten.bgTelefon">
                                        {{ stammdaten.bgTelefon }}
                                    </p>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <!-- Beschwerdeadresse -->
                    <div
                        *ngIf="
                            stammdaten.standardRechtsmittelbelehrung;
                            else displayRechtsmittelbelehrung
                        "
                        class="mt-5"
                    >
                        <div
                            *ngIf="
                                keineBeschwerdeAdresse ||
                                    !stammdaten.beschwerdeAdresse;
                                else displayBeschwerdeAdresse
                            "
                        >
                            <div class="form-group">
                                <h3 translate>BESCHWERDE_ADRESSE</h3>
                                <p translate>
                                    BESCHWERDE_ADRESSE_IDENTISCH_KONTAKT
                                </p>
                            </div>
                        </div>
                        <ng-template #displayBeschwerdeAdresse>
                            <div class="form-group">
                                <h3 translate>BESCHWERDE_ADRESSE</h3>
                                <p>
                                    {{
                                        stammdaten.beschwerdeAdresse
                                            .organisation
                                    }}
                                </p>
                                <p>
                                    {{ stammdaten.beschwerdeAdresse.strasse }}
                                    {{
                                        stammdaten.beschwerdeAdresse.hausnummer
                                    }}
                                </p>
                                <p>
                                    {{ stammdaten.beschwerdeAdresse.plz }}
                                    {{ stammdaten.beschwerdeAdresse.ort }}
                                </p>
                            </div>
                        </ng-template>
                    </div>
                    <ng-template #displayRechtsmittelbelehrung>
                        <div class="form-group mt-5">
                            <div *ngIf="stammdaten.korrespondenzspracheDe">
                                <h3 translate>RECHTSMITTELBELEHRUNG_TEXT_DE</h3>
                                <p>
                                    {{
                                        stammdaten.rechtsmittelbelehrung
                                            .textDeutsch
                                    }}
                                </p>
                            </div>
                            <div *ngIf="stammdaten.korrespondenzspracheFr">
                                <h3 translate>RECHTSMITTELBELEHRUNG_TEXT_FR</h3>
                                <p>
                                    {{
                                        stammdaten.rechtsmittelbelehrung
                                            .textFranzoesisch
                                    }}
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <dv-accordion
                        class="konfigurationList"
                        allow-multiple-sections="true"
                    >
                        <ng-container
                            *ngFor="
                                let konf of konfigurationsListe;
                                let i = index
                            "
                        >
                            <dv-accordion-tab
                                [tabid]="i"
                                *ngIf="
                                    konfigurationsListe[
                                        i
                                    ].gesuchsperiode.gueltigkeit.gueltigBis.isAfter(
                                        beguStartDatum
                                    )
                                "
                            >
                                <tab-title>
                                    <div class="message-header">
                                        <p class="fa fa-angle-right"></p>
                                        <span
                                            translate
                                            [translateParams]="{
                                                fromTo: konf.gesuchsperiodeName,
                                                status: konf.gesuchsperiodeStatusName
                                            }"
                                        >
                                            GEMEINDE_TAB_BG_GP_TITLE
                                        </span>
                                    </div>
                                </tab-title>
                                <tab-body>
                                    <div class="row">
                                        <!-- readonly -->
                                        <div
                                            *ngIf="
                                                !isKonfigurationEditable(
                                                    konfigurationsListe[i]
                                                )
                                            "
                                            class="col-sm-12"
                                        >
                                            <div class="form-group">
                                                <!-- BG bis Schulstufe -->
                                                <label
                                                    *ngIf="
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                    >{{
                                                        'BEGU_KITAS_BIS_UND_MIT'
                                                            | translate
                                                    }}&nbsp;<strong>{{
                                                        getKonfigBeguBisUndMitSchulstufeString(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                        )
                                                    }}</strong></label
                                                >
                                                <!-- Kontingentierung -->
                                                <div
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKontingentierung
                                                        ) &&
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                >
                                                    <p
                                                        *ngIf="
                                                            !konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKontingentierung
                                                        "
                                                        class="line-through"
                                                    >
                                                        {{
                                                            getKonfigKontingentierungString()
                                                        }}
                                                    </p>
                                                    <p
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKontingentierung
                                                        "
                                                    >
                                                        {{
                                                            getKonfigKontingentierungString()
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKeineGutscheineFuerSozialhilfeEmpfaenger
                                                        )
                                                    "
                                                >
                                                    <p
                                                        *ngIf="
                                                            !konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKeineGutscheineFuerSozialhilfeEmpfaenger
                                                        "
                                                        class="line-through"
                                                        translate
                                                    >
                                                        GEMEINDE_KEIN_GUTSCHEIN_FUER_SOZIALHILFE_EMPFAENGER
                                                    </p>
                                                    <p
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigKeineGutscheineFuerSozialhilfeEmpfaenger
                                                        "
                                                        translate
                                                    >
                                                        GEMEINDE_KEIN_GUTSCHEIN_FUER_SOZIALHILFE_EMPFAENGER
                                                    </p>
                                                </div>

                                                <!-- Erwerbspensum Zuschlag -->
                                                <p
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlag
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .erwerbspensumZuschlagOverriden &&
                                                        !gemeindeVereinfachteKonfigAktiv
                                                    "
                                                >
                                                    <span
                                                        *ngIf="
                                                            !konfigurationsListe[
                                                                i
                                                            ].isTextForFKJV
                                                        "
                                                        translate
                                                        [translateParams]="{
                                                            valueMandant:
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumZuschlagMax,
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlag
                                                        }"
                                                    >
                                                        EWP_ZUSCHLAG_OVERRIDE_WITH_VALUE
                                                    </span>
                                                    <span
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ].isTextForFKJV
                                                        "
                                                        translate
                                                        [translateParams]="{
                                                            valueMandant:
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumZuschlagMax,
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumZuschlag
                                                        }"
                                                    >
                                                        EWP_ZUSCHLAG_OVERRIDE_WITH_VALUE_FKJV
                                                    </span>
                                                </p>
                                                <!-- Minimale Erwerbspensen -->
                                                <p
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumMinimumOverriden
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .erwerbspensumMinimumOverriden
                                                    "
                                                >
                                                    <span
                                                        translate
                                                        [translateParams]="{
                                                            valueMandant:
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumVorschuleMax,
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumMiminumVorschule
                                                        }"
                                                    >
                                                        EWP_MINIUM_VORSCHULKINDER_OVERRIDE_WITH_VALUE
                                                    </span>
                                                    <br />
                                                    <span
                                                        translate
                                                        [translateParams]="{
                                                            valueMandant:
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .erwerbspensumMiminumSchulkinderMax,
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .erwerbspensumMiminumSchulkinder
                                                        }"
                                                    >
                                                        EWP_MINIUM_SCHULKINDER_OVERRIDE_WITH_VALUE
                                                    </span>
                                                </p>
                                                <!-- Zusaetzlicher Gutschein der Gemeinde -->
                                                <div
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinEnabled
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .konfigZusaetzlicherGutscheinEnabled
                                                    "
                                                    class="mt-2 margin-bottom-20"
                                                >
                                                    <strong
                                                        >{{
                                                            'ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE'
                                                                | translate
                                                        }}
                                                        :</strong
                                                    >
                                                    <p translate>
                                                        {{
                                                            'GEMEINDE_ZUSAETZLICHER_GUTSCHEIN_TYP_' +
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinTyp
                                                                | translate
                                                        }}
                                                    </p>
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragKita
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                        "
                                                    >
                                                        <p
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragKita
                                                            }"
                                                        >
                                                            ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_KITA_VALUE_READONLY
                                                        </p>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragTfo
                                                            ) &&
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                                TSGemeindeZusaetzlicherGutscheinTyp.PAUSCHAL
                                                        "
                                                    >
                                                        <p
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBetragTfo
                                                            }"
                                                        >
                                                            ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_TFO_VALUE_READONLY
                                                        </p>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinTyp ===
                                                            TSGemeindeZusaetzlicherGutscheinTyp.LINEAR
                                                        "
                                                    >
                                                        <p
                                                            *ngIf="
                                                                !isUndefined(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinLinearMaxBetragKita
                                                                )
                                                            "
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragKita
                                                            }"
                                                        >
                                                            ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_LINEAR_MAX_VALUE_KITA_READONLY
                                                        </p>
                                                        <p
                                                            *ngIf="
                                                                !isUndefined(
                                                                    konfigurationsListe[
                                                                        i
                                                                    ]
                                                                        .konfigZusaetzlicherGutscheinLinearMaxBetragTfo
                                                                )
                                                            "
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinLinearMaxBetragTfo
                                                            }"
                                                        >
                                                            ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_LINEAR_MAX_VALUE_TFO_READONLY
                                                        </p>
                                                    </ng-container>
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeKita
                                                            )
                                                        "
                                                    >
                                                        <p>
                                                            {{
                                                                'ZUSAETZLICHER_GUTSCHEIN_KITA_BIS_UND_MIT'
                                                                    | translate
                                                            }}
                                                            {{
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeKita
                                                                    | translate
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeTfo
                                                            )
                                                        "
                                                    >
                                                        <p>
                                                            {{
                                                                'ZUSAETZLICHER_GUTSCHEIN_TFO_BIS_UND_MIT'
                                                                    | translate
                                                            }}
                                                            {{
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinBisUndMitSchulstufeTfo
                                                                    | translate
                                                            }}
                                                        </p>
                                                    </div>
                                                    <p
                                                        *ngIf="
                                                            !isNullOrUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMinMassgebendesEinkommen
                                                            )
                                                        "
                                                        translate
                                                        [translateParams]="{
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinMinMassgebendesEinkommen
                                                        }"
                                                    >
                                                        ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_MIN_MASSGEBENDES_EINKOMMEN_READONLY
                                                    </p>
                                                    <p
                                                        *ngIf="
                                                            !isNullOrUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherGutscheinMaxMassgebendesEinkommen
                                                            )
                                                        "
                                                        translate
                                                        [translateParams]="{
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherGutscheinMaxMassgebendesEinkommen
                                                        }"
                                                    >
                                                        ZUSAETZLICHE_VERGUENSTIGUNG_GEMEINDE_MAX_MASSGEBENDES_EINKOMMEN_READONLY
                                                    </p>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherBabybeitragEnabled
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .konfigZusaetzlicherBabybeitragEnabled
                                                    "
                                                    class="mt-2"
                                                >
                                                    <strong
                                                        >{{
                                                            'ZUSAETZLICHER_BABYBEITRAG_GEMEINDE'
                                                                | translate
                                                                    : {
                                                                          monate: getDauerBabytarif(
                                                                              konf.gesuchsperiode
                                                                          )
                                                                      }
                                                        }}
                                                        :</strong
                                                    >
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragKita
                                                            )
                                                        "
                                                    >
                                                        <p
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragKita
                                                            }"
                                                        >
                                                            ZUSAETZLICHER_BABYBEITRAG_BETRAG_KITA_READONLY
                                                        </p>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            !isUndefined(
                                                                konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragTfo
                                                            )
                                                        "
                                                    >
                                                        <p
                                                            translate
                                                            [translateParams]="{
                                                                value: konfigurationsListe[
                                                                    i
                                                                ]
                                                                    .konfigZusaetzlicherBabybeitragBetragTfo
                                                            }"
                                                        >
                                                            ZUSAETZLICHER_BABYBEITRAG_BETRAG_TFO_READONLY
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        !isUndefined(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled
                                                        ) &&
                                                        konfigurationsListe[i]
                                                            .konfigZusaetzlicherAnspruchFreiwilligenarbeitEnabled
                                                    "
                                                    class="mt-2 margin-bottom-20"
                                                >
                                                    <strong
                                                        >{{
                                                            'ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT'
                                                                | translate
                                                        }}
                                                        :</strong
                                                    >
                                                    <p
                                                        translate
                                                        [translateParams]="{
                                                            value: konfigurationsListe[
                                                                i
                                                            ]
                                                                .konfigZusaetzlicherAnspruchFreiwilligenarbeitMaxprozent
                                                        }"
                                                    >
                                                        ZUSAETZLICHER_ANSPRUCH_FREIWILLIGENARBEIT_MAX_READONLY
                                                    </p>
                                                </div>
                                                <!-- Abhängikeit Anspruch und Beschäftigungspensum -->
                                                <span *ngIf="isSuperAdmin()"
                                                    >{{
                                                        'ABHAENGIGKEIT_ANSPRUCH_BESCHAEFTIGUNGPENSUM'
                                                            | translate
                                                    }}:
                                                    <strong>{{
                                                        getKonfigAbhaengigkeitAnspruchBeschaeftigungspensum(
                                                            konfigurationsListe[
                                                                i
                                                            ]
                                                        )
                                                    }}</strong></span
                                                >

                                                <!-- Gemeindespezifische Konfurationen für BG -->
                                                <div
                                                    *ngIf="
                                                        showGemeindespezifischeKonfigForBG(
                                                            konfigurationsListe[
                                                                i
                                                            ].gesuchsperiode
                                                        )
                                                    "
                                                    class="mt-2"
                                                >
                                                    <strong
                                                        >{{
                                                            'GEMEINDESPEZIFISCHE_BG_KONFIGURATIONEN_TITLE'
                                                                | translate
                                                        }}
                                                        :</strong
                                                    >
                                                    <ng-container
                                                        *ngFor="
                                                            let config of konfigurationsListe[
                                                                i
                                                            ]
                                                                .gemeindespezifischeBGKonfigurationen
                                                        "
                                                    >
                                                        <p
                                                            translate
                                                            [translateParams]="{
                                                                value: config.value
                                                            }"
                                                        >
                                                            {{
                                                                config.key
                                                            }}_READONLY
                                                        </p>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Mahlzeitenverguenstigung -->
                                        <div
                                            class="col-sm-12"
                                            *ngIf="
                                                !isUndefined(
                                                    konfigurationsListe[i]
                                                        .konfigMahlzeitenverguenstigungEnabled
                                                ) &&
                                                konfigurationsListe[i]
                                                    .konfigMahlzeitenverguenstigungEnabled
                                            "
                                        >
                                            <p>
                                                <strong translate
                                                    >MAHLZEITENVERGUENSTIGUNG_DURCH_GEMEINDE</strong
                                                >
                                            </p>
                                            <p
                                                translate
                                                [translateParams]="{
                                                    value: konfigurationsListe[
                                                        i
                                                    ]
                                                        .konfigMahlzeitenverguenstigungMinimalerElternbeitragMahlzeit
                                                }"
                                            >
                                                MINIMALER_ELTERNBEITRAG_MAHLZEITEN_READONLY
                                            </p>

                                            <!-- Mahlzeitenverguenstigung pro Einkommensstufe-->
                                            <div
                                                class="table-responsive"
                                                *ngIf="
                                                    konfigurationsListe[i]
                                                        .konfigMahlzeitenverguenstigungEnabled
                                                "
                                            >
                                                <table
                                                    class="mahlzeitenverguenstigung-table"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <td translate>
                                                                MAHLZEITENVERGUENSTIGUNG_MASSGEBENDES_EINKOMMEN
                                                            </td>
                                                            <td
                                                                class="align-right"
                                                                translate
                                                            >
                                                                VERGUENSTIGUNG_PRO_MAHLZEIT
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{
                                                                    'MAHLZEITENVERGUENSTIGUNG_NULL_EINKOMMEN'
                                                                        | translate
                                                                }}
                                                                -
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                class="align-right"
                                                            >
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe1VerguenstigungMahlzeit.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{
                                                                    (
                                                                        konfigurationsListe[
                                                                            i
                                                                        ]
                                                                            .konfigMahlzeitenverguenstigungEinkommensstufe1MaxEinkommen +
                                                                        1
                                                                    ).toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                                -
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe2MaxEinkommen.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                class="align-right"
                                                            >
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe2VerguenstigungMahlzeit.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{
                                                                    'AB'
                                                                        | translate
                                                                }}
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe2MaxEinkommen.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                class="align-right"
                                                            >
                                                                {{
                                                                    konfigurationsListe[
                                                                        i
                                                                    ].konfigMahlzeitenverguenstigungEinkommensstufe3VerguenstigungMahlzeit.toLocaleString(
                                                                        'de-CH'
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--                                            Mahlzeitenverguenstigung auch für Sozialhilfeempfänger-->
                                            <p
                                                *ngIf="
                                                    konfigurationsListe[i]
                                                        .konfigMahlzeitenverguenstigungFuerSozialhilfebezuegerEnabled
                                                "
                                                translate
                                            >
                                                MAHLZEITENVERGUENSTIGUNG_AUCH_FUER_SOZIALHILFEEMPFAENGER
                                            </p>
                                        </div>
                                        <div class="col-sm-6">
                                            <!-- Zusaetzlicher Gutschein der Gemeinde -->
                                            <div
                                                *ngIf="
                                                    konfigurationsListe[i]
                                                        .konfigHoheEinkommensklassenAktiviert
                                                "
                                                class="mt-2"
                                            >
                                                <strong
                                                    >{{
                                                        'GEMEINDE_HOHE_EINKOMMENSKLASSEN'
                                                            | translate
                                                    }}
                                                    :</strong
                                                >
                                                <p
                                                    translate
                                                    [translateParams]="{
                                                        value: konfigurationsListe[
                                                            i
                                                        ]
                                                            .konfigHoheEinkommensklassenBetragKita
                                                    }"
                                                >
                                                    GEMEINDE_HOHE_EINKOMMENSKLASSEN_BETRAG_KITA_VALUE_READONLY
                                                </p>
                                                <p
                                                    translate
                                                    [translateParams]="{
                                                        value: konfigurationsListe[
                                                            i
                                                        ]
                                                            .konfigHoheEinkommensklassenBetragTfo
                                                    }"
                                                >
                                                    GEMEINDE_HOHE_EINKOMMENSKLASSEN_BETRAG_TFO_VALUE_READONLY
                                                </p>
                                                <p
                                                    translate
                                                    [translateParams]="{
                                                        value: konfigurationsListe[
                                                            i
                                                        ]
                                                            .konfigHoheEinkommensklassenMassgebendenEinkommen
                                                    }"
                                                >
                                                    GEMEINDE_HOHE_EINKOMMENSKLASSEN_MASSGEBENDENEINKOMMEN_VALUE_READONLY
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </tab-body>
                            </dv-accordion-tab>
                        </ng-container>
                    </dv-accordion>
                </div>
            </div>
        </fieldset>
    </ng-template>
</div>
