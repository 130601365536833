<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div [class]="'checkbox ' + marginClass">
    <mat-checkbox
        [(ngModel)]="model"
        [disabled]="readonly"
        [name]="uniqueName"
        [id]="uniqueName + '_id'"
        [aria-label]="label | translate"
        data-test="checkbox"
        (ngModelChange)="change()"
    >
        <span>{{ label | translate }}</span>
        <dv-tooltip *ngIf="tooltip" [text]="tooltip | translate"></dv-tooltip>
    </mat-checkbox>
    <dv-bisher-x
        [korrektur]="model"
        [deklaration]="dvBisherValue"
        [showBisher]="showBisher()"
    ></dv-bisher-x>
</div>
