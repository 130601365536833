<!--
  ~ Copyright (C) 2018 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->

<dv-full-height-inner-padding-container>
    <form
        role="form"
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        (ngSubmit)="navigateToStartPage()"
        novalidate
    >
        <div class="dv-content">
            <div class="col-sm-12 welcome-title-cotainer">
                <span class="welcome-title" translate> WELCOME </span>
            </div>

            <div class="row">
                <div class="col-sm-12" style="text-align: center">
                    <div class="welcome-image" [style.content]="logoUrl"></div>
                </div>
            </div>

            <div
                *ngIf="isNotSozialdienstRole()"
                class="col-sm-12 welcome-title-cotainer"
            >
                <p
                    class="p-bg"
                    style="padding: 0 12rem 0 12rem"
                    [innerHTML]="'TOUR_WELCOME_TEXT' | translate"
                ></p>
            </div>

            <ng-content></ng-content>
        </div>

        <div class="dv-footer" style="padding-bottom: 5rem">
            <dv-loading-button
                *ngIf="isNotSozialdienstRole()"
                [type]="'submit'"
                [buttonClass]="'dv-btn-bg'"
            >
                <span translate>LETS_START</span>
            </dv-loading-button>

            <button
                *ngIf="isNotSozialdienstRole()"
                type="button"
                class="dv-btn dv-btn-bg cancel-button"
                (click)="cancel()"
                [textContent]="'CANCEL' | translate"
            ></button>
            <dv-loading-button
                *ngIf="!isNotSozialdienstRole()"
                [type]="'button'"
                [buttonClass]="'dv-btn-bg'"
                (buttonClick)="cancel()"
            >
                <span translate>LETS_START</span>
            </dv-loading-button>
        </div>
    </form>
</dv-full-height-inner-padding-container>
