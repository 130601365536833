<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="row" *ngIf="!isEKV">
    <div class="col-xs-12">
        <mat-divider class="margin-bottom-50"></mat-divider>
        <h3 class="uppercase ebegu-h1" *ngIf="isGemeinsam">
            {{
                'SELBSTDEKLARATION_GEMEINSAM_TITLE'
                    | translate
                        : {
                              gs1: antragsteller1Name(),
                              gs2: antragsteller2Name(),
                              year: basisJahr
                          }
            }}
        </h3>
        <h3 class="uppercase ebegu-h1" *ngIf="!isGemeinsam">
            {{
                'SELBSTDEKLARATION_ALLEINE_TITLE'
                    | translate
                        : {gs1: getCurrentAntragstellerName(), year: basisJahr}
            }}
        </h3>
        <p
            class="inline-hint margin-top-10 margin-bottom-10"
            *ngIf="!isQuellenbesteuert"
            translate
        >
            SELBSTDEKLARATION_SUBTITLE
        </p>
    </div>
</div>

<div class="row">
    <dv-warning *ngIf="!isQuellenbesteuert"
        >SELBSTDEKLARATION_WARNING</dv-warning
    >
    <div class="col-xs-12">
        <h3 class="margin-bottom-20">
            {{ 'EINKUENFTE_IM_JAHR' | translate }} {{ basisJahr }}
        </h3>
    </div>
    <div class="col-xs-12 dv-input-container-medium">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftErwerb"
            [labelMessageKey]="'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ERWERB'"
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ERWERB_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.einkunftErwerb"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftErwerb
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftErwerb
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftErwerb
            "
            [korrektur]="model.selbstdeklaration?.einkunftErwerb"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftVersicherung"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERSICHERUNG'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERSICHERUNG_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.einkunftVersicherung"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftVersicherung
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftVersicherung
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftVersicherung
            "
            [korrektur]="model.selbstdeklaration?.einkunftVersicherung"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftWertschriften"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_WERTSCHRIFTEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_WERTSCHRIFTEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.einkunftWertschriften"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftWertschriften
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftWertschriften
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftWertschriften
            "
            [korrektur]="model.selbstdeklaration?.einkunftWertschriften"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftUnterhaltsbeitragKinder"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_UNTERHALT_KINDER'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_UNTERHALT_KINDER_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="
                model.selbstdeklaration.einkunftUnterhaltsbeitragKinder
            "
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftUnterhaltsbeitragKinder
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftUnterhaltsbeitragKinder
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftUnterhaltsbeitragKinder
            "
            [korrektur]="
                model.selbstdeklaration?.einkunftUnterhaltsbeitragKinder
            "
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftUeberige"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_UEBERIGE'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_UEBERIGE_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.einkunftUeberige"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftUeberige
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftUeberige
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftUeberige
            "
            [korrektur]="model.selbstdeklaration?.einkunftUeberige"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.einkunftLiegenschaften"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_LIEGENSCHAFTEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_LIEGENSCHAFTEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.einkunftLiegenschaften"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.einkunftLiegenschaften
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.einkunftLiegenschaften
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.einkunftLiegenschaften
            "
            [korrektur]="model.selbstdeklaration?.einkunftLiegenschaften"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [model]="getEinkommenForCurrentAntragsteller()"
            [labelMessageKey]="'FINANZIELLE_SITUATION_EINKOMMEN_EIN_GS'"
            [inputDisabled]="true"
        >
        </dv-input-label-field>
    </div>
</div>
<mat-divider></mat-divider>
<h3 class="margin-bottom-20">
    {{ 'ABZUEGE_IM_JAHR' | translate }} {{ basisJahr }}
</h3>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugBerufsauslagen"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_BERUFSAUSLAGEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_BERUFSAUSLAGEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugBerufsauslagen"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugBerufsauslagen
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugBerufsauslagen
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugBerufsauslagen
            "
            [korrektur]="model.selbstdeklaration?.abzugBerufsauslagen"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugSchuldzinsen"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_SCHULDZINSEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_SCHULDZINSEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugSchuldzinsen"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugSchuldzinsen
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugSchuldzinsen
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.abzugSchuldzinsen
            "
            [korrektur]="model.selbstdeklaration?.abzugSchuldzinsen"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugUnterhaltsbeitragKinder"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_UNTERHALT_KINDER'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_UNTERHALT_KINDER_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugUnterhaltsbeitragKinder"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugUnterhaltsbeitragKinder
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugUnterhaltsbeitragKinder
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugUnterhaltsbeitragKinder
            "
            [korrektur]="model.selbstdeklaration?.abzugUnterhaltsbeitragKinder"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugSaeule3A"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_SAEULE3A'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_SAEULE3A_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugSaeule3A"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugSaeule3A
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugSaeule3A
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugSaeule3A
            "
            [korrektur]="model.selbstdeklaration?.abzugSaeule3A"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugVersicherungspraemien"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERSICHERUNGSPRAEMIE'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERSICHERUNGSPRAEMIE_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugVersicherungspraemien"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugVersicherungspraemien
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugVersicherungspraemien
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugVersicherungspraemien
            "
            [korrektur]="model.selbstdeklaration?.abzugVersicherungspraemien"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugKrankheitsUnfallKosten"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_KRANKHEIT_UNFALL'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_KRANKHEIT_UNFALL_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugKrankheitsUnfallKosten"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugKrankheitsUnfallKosten
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugKrankheitsUnfallKosten
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugKrankheitsUnfallKosten
            "
            [korrektur]="model.selbstdeklaration?.abzugKrankheitsUnfallKosten"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="
                model.selbstdeklaration.sonderabzugErwerbstaetigkeitEhegatten
            "
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SONDERABZUG_ERWERBSTAETIGKEIT_EHEGATTEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SONDERABZUG_ERWERBSTAETIGKEIT_EHEGATTEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="
                model.selbstdeklaration.sonderabzugErwerbstaetigkeitEhegatten
            "
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration
                          ?.sonderabzugErwerbstaetigkeitEhegatten
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.sonderabzugErwerbstaetigkeitEhegatten
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration
                          ?.sonderabzugErwerbstaetigkeitEhegatten
            "
            [korrektur]="
                model.selbstdeklaration?.sonderabzugErwerbstaetigkeitEhegatten
            "
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugKinderVorschule"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_KINDER_VORSCHULE'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_KINDER_VORSCHULE_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugKinderVorschule"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugKinderVorschule
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugKinderVorschule
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugKinderVorschule
            "
            [korrektur]="model.selbstdeklaration?.abzugKinderVorschule"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugKinderSchule"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_KINDER_SCHULE'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_KINDER_SCHULE_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugKinderSchule"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugKinderSchule
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugKinderSchule
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugKinderSchule
            "
            [korrektur]="model.selbstdeklaration?.abzugKinderSchule"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugEigenbetreuung"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_EIGENBETREUUNG'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_EIGENBETREUUNG_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugEigenbetreuung"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugEigenbetreuung
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugEigenbetreuung
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugEigenbetreuung
            "
            [korrektur]="model.selbstdeklaration?.abzugEigenbetreuung"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugFremdbetreuung"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_FREMDBETREUUNG'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_FREMDBETREUUNG_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugFremdbetreuung"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugFremdbetreuung
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugFremdbetreuung
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugFremdbetreuung
            "
            [korrektur]="model.selbstdeklaration?.abzugFremdbetreuung"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugErwerbsunfaehigePersonen"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ERWERBSUNFAEHIG'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ERWERBSUNFAEHIG_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugErwerbsunfaehigePersonen"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugErwerbsunfaehigePersonen
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugErwerbsunfaehigePersonen
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugErwerbsunfaehigePersonen
            "
            [korrektur]="model.selbstdeklaration?.abzugErwerbsunfaehigePersonen"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [model]="getAbzuegeForCurrentAntragsteller()"
            [labelMessageKey]="'FINANZIELLE_SITUATION_TOTAL_ABZUEGE'"
            [inputDisabled]="true"
        >
        </dv-input-label-field>
    </div>
</div>
<mat-divider></mat-divider>
<h3 class="margin-bottom-20">
    {{ 'VERMOEGEN_IM_JAHR' | translate }} {{ basisJahr }}
</h3>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.vermoegen"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERMOEGEN'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_VERMOEGEN_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.vermoegen"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.vermoegen
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.vermoegen
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.vermoegen
            "
            [korrektur]="model.selbstdeklaration?.vermoegen"
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="
                model.selbstdeklaration.abzugSteuerfreierBetragErwachsene
            "
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_STEUERFREI_ERWACHSENE'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_STEUERFREI_ERWACHSENE_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="
                model.selbstdeklaration.abzugSteuerfreierBetragErwachsene
            "
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugSteuerfreierBetragErwachsene
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugSteuerfreierBetragErwachsene
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugSteuerfreierBetragErwachsene
            "
            [korrektur]="
                model.selbstdeklaration?.abzugSteuerfreierBetragErwachsene
            "
        >
        </dv-input-label-field>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [(model)]="model.selbstdeklaration.abzugSteuerfreierBetragKinder"
            [labelMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_STEUERFREI_KINDER'
            "
            [tooltipMessageKey]="
                'FINANZIELLE_SITUATION_SELBSTDEKLARATION_ABZUG_STEUERFREI_KINDER_HELP'
            "
            [inputRequired]="true"
            [dvOnBlur]="onValueChangeFunction"
            [inputDisabled]="readOnly"
            [neuerWert]="model.selbstdeklaration.abzugSteuerfreierBetragKinder"
            [vergleichswert]="
                isEKV
                    ? finanzModel.getFiSiConToWorkWith().finanzielleSituationJA
                          .selbstdeklaration?.abzugSteuerfreierBetragKinder
                    : undefined
            "
            [vergleichwertLabel]="
                'EINGABE_BASISJAHR'
                    | translate: {basisjahr: finanzModel.getBasisjahr()}
            "
            [showBisher]="
                isEKV
                    ? showBisher(finanzModel.getEkvToWorkWith_GS())
                    : showBisher(
                          finanzModel.getFiSiConToWorkWith()
                              .finanzielleSituationGS
                      )
            "
            [deklaration]="
                isEKV
                    ? finanzModel.getEkvToWorkWith_GS()?.selbstdeklaration
                          ?.abzugSteuerfreierBetragKinder
                    : finanzModel.getFiSiConToWorkWith().finanzielleSituationGS
                          ?.selbstdeklaration?.abzugSteuerfreierBetragKinder
            "
            [korrektur]="model.selbstdeklaration?.abzugSteuerfreierBetragKinder"
        >
        </dv-input-label-field>
    </div>
</div>

<div class="row">
    <div class="col-sm-6">
        <dv-input-label-field
            [model]="getVermoegenForCurrentAntragsteller()"
            [labelMessageKey]="
                'ANRECHENBARES_VERMOEGEN_GEMAESS_SELBSTDEKLARATION'
            "
            [tooltipMessageKey]="
                'ANRECHENBARES_VERMOEGEN_GEMAESS_SELBSTDEKLARATION_HINT'
            "
            [inputDisabled]="true"
        >
        </dv-input-label-field>
    </div>
</div>
