<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div class="notrecht admin-modul" flex="auto">
    <div class="col-md-8 col-md-offset-2">
        <h1 class="access-for-all-title" translate>CORONA_FINANZIERUNG</h1>
        <div class="view-title">
            <h2 translate>CORONA_FINANZIERUNG</h2>
            <hr class="header" />
        </div>

        <div class="form-group" *ngIf="isMandantOrSuperuser()" id="filters">
            <mat-checkbox
                id="showOnlyOffenePendenzen_id"
                name="showOnlyOffenePendenzen_id"
                [(ngModel)]="showOnlyOffenePendenzen"
                (change)="filterRueckforderungFormulare()"
            >
                {{ 'CORONA_LISTE_NUR_OFFENE_PENDENZEN' | translate }}
            </mat-checkbox>
            <mat-checkbox
                id="showOnlyMirZugewieseneAntraege_id"
                name="showOnlyMirZugewieseneAntraege_name"
                [(ngModel)]="showOnlyMirZugewieseneAntraege"
                (change)="filterRueckforderungFormulare()"
            >
                {{ 'CORONA_LISTE_NUR_MIR_ZUGEWIESENE' | translate }}
            </mat-checkbox>
            <mat-checkbox
                id="showOnlyAntraegeWithDokumenten_id"
                name="showOnlyAntraegeWithDokumenten_name"
                [(ngModel)]="showOnlyAntraegeWithDokumenten"
                (change)="filterRueckforderungFormulare()"
            >
                {{ 'CORONA_LISTE_NUR_DOKUMENTE' | translate }}
            </mat-checkbox>
        </div>

        <div class="filter">
            <input
                class="filter"
                matInput
                type="text"
                (keyup)="doFilter($event.target.value)"
                placeholder="{{ 'SUCHBEGRIFF_EINGEBEN' | translate }}"
            />
        </div>

        <div class="table-responsive notrecht-table">
            <mat-table
                #table
                [dataSource]="rueckforderungFormulareSource"
                matSort
                matSortActive="institution.name"
            >
                <!-- Institution -->
                <ng-container
                    matColumnDef="institutionStammdaten.institution.name"
                >
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="institutionStammdaten.institution.name"
                    >
                        {{ 'INSTITUTION' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        {{ formular.institutionStammdaten.institution.name }}
                    </mat-cell>
                </ng-container>

                <!-- Typ -->
                <ng-container
                    matColumnDef="institutionStammdaten.betreuungsangebotTyp"
                >
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="institutionStammdaten.betreuungsangebotTyp"
                    >
                        {{ 'TYP' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                        translate
                    >
                        {{
                            formular.institutionStammdaten.betreuungsangebotTyp
                        }}
                    </mat-cell>
                </ng-container>

                <!-- Typ -->
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'STATUS' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        {{ translateRueckforderungStatus(formular.status) }}
                    </mat-cell>
                </ng-container>

                <!-- Zahlung Stufe 1 ausgelöst -->
                <ng-container matColumnDef="zahlungStufe1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ZAHLUNG_STUFE_1_AUSGELOEST' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        {{
                            getZahlungAusgeloest(
                                formular.stufe1FreigabeAusbezahltAm
                            )
                        }}
                    </mat-cell>
                </ng-container>

                <!-- Zahlung Stufe 2 ausgelöst -->
                <ng-container matColumnDef="zahlungStufe2">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="zahlungStufe2"
                    >
                        {{ 'ZAHLUNG_STUFE_2_AUSGELOEST' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        {{
                            getZahlungAusgeloest(
                                formular.stufe2VerfuegungAusbezahltAm
                            )
                        }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="verantwortlich">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'VERANTWORTLICH' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        {{ formular.verantwortlicherName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dokumente">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <i class="fa fa-lg fa-file-o"></i>
                    </mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        <i
                            *ngIf="formular.uncheckedDocuments === true"
                            class="fa fa-lg fa-file-o"
                        ></i>
                    </mat-cell>
                </ng-container>

                <!-- clickable hint -->
                <ng-container matColumnDef="is-clickable">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        (click)="openRueckforderungFormular(formular, $event)"
                        [ngClass]="{
                            'mat-cell-clickable': openFormularAllowed(formular)
                        }"
                        *matCellDef="let formular"
                    >
                        <dv-loading-button
                            (buttonClick)="
                                openRueckforderungFormular(formular, $event)
                            "
                            buttonClass="dv-btn-sm"
                            *ngIf="openFormularAllowed(formular)"
                        >
                            <i class="fa fa-eye"></i>
                        </dv-loading-button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="getDisplayColumns()"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: getDisplayColumns()"
                ></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
        <div class="notrecht-buttons">
            <button
                class="dv-btn"
                (click)="initializeRueckforderungFormulare()"
                *ngIf="isSuperAdmin()"
                translate
            >
                RUECKFORDERUNGSFORMULARE_INITIALISIEREN
            </button>

            <button
                class="dv-btn"
                (click)="sendMitteilung(true)"
                *ngIf="showMitteilungSenden()"
                translate
            >
                NOTVERORDNUNG_EINLADUNG_SENDEN
            </button>

            <button
                class="dv-btn"
                (click)="sendMitteilung(false)"
                *ngIf="showMitteilungSenden()"
                translate
            >
                NOTVERORDNUNG_MITTEILUNG_SENDEN
            </button>

            <button
                class="dv-btn"
                (click)="initializePhase2()"
                *ngIf="isSuperAdmin()"
                translate
            >
                NOTVERORDNUNG_INITIALISIEREN_PHASE2
            </button>

            <button
                class="dv-btn"
                (click)="verfuegenUndAusdrucken()"
                *ngIf="isMandantOrSuperuser()"
                translate
            >
                NOTVERORDNUNG_VERFUEGUNGEN_AUSDRUCKEN
            </button>
        </div>
    </div>
</div>
