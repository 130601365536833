<!--
  ~ Copyright (C) 2020 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<dv-full-height-container>
    <form
        role="form"
        #form="ngForm"
        [ngClass]="{'ng-submitted': form.submitted}"
        *ngIf="rueckforderungFormular$ | async as rueckforderungFormular"
        (ngSubmit)="saveRueckforderungFormular(rueckforderungFormular)"
        novalidate
    >
        <div class="notrecht admin-modul" flex="auto">
            <div class="col-md-8 col-md-offset-2">
                <div
                    class="row"
                    *ngIf="showFristAbgelaufen(rueckforderungFormular)"
                >
                    <div class="col-md-12">
                        <div class="well well-status-warten">
                            <span>{{
                                'RUECKFORDERUNG_WARNING_FRIST_ERREICHT'
                                    | translate
                            }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    *ngIf="!fristSchonErreicht(rueckforderungFormular)"
                >
                    <div class="col-md-12">
                        <div class="well well-status-bestaetigt">
                            <i class="fa fa-info" aria-hidden="true"></i>
                            <span>{{
                                getFristBis(rueckforderungFormular)
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="row view-title">
                    <div class="col-md-12">
                        <h2>
                            {{
                                'RUECKFORDERUNGSFORMULARE_DETAIL'
                                    | translate
                                        : {
                                              name: rueckforderungFormular
                                                  .institutionStammdaten
                                                  .institution.name
                                          }
                            }}

                            <span *ngIf="isKantonBenutzer()">
                                <button
                                    class="dv-btn"
                                    (click)="
                                        setCurrentUserAsVerantwortlicher(
                                            rueckforderungFormular
                                        )
                                    "
                                    [textContent]="'LABEL_ZUWEISEN' | translate"
                                ></button>
                            </span>
                            <span class="status-header">
                                {{
                                    translateStatus(
                                        rueckforderungFormular.status
                                    )
                                }}<br />
                                <span
                                    *ngIf="
                                        rueckforderungFormular.verantwortlicherName &&
                                        isKantonBenutzer()
                                    "
                                >
                                    {{
                                        'VERANTWORTLICHER'
                                            | translate
                                                : {
                                                      name: rueckforderungFormular.verantwortlicherName
                                                  }
                                    }}
                                </span>
                            </span>
                        </h2>
                        <hr class="header" />
                    </div>
                </div>
                <div
                    class="row"
                    *ngIf="isInstitutionStufe1ReadOnly(rueckforderungFormular)"
                >
                    <div class="col-md-12 margin-bottom-20">
                        {{
                            'CONFIRMATON_AFTER_IN_BEARBEITUNG_INSTITUTION_STUFE_1'
                                | translate
                        }}
                    </div>
                </div>
                <div
                    class="row"
                    *ngIf="isInstitutionStufe2ReadOnly(rueckforderungFormular)"
                >
                    <div class="col-md-12 margin-bottom-20">
                        {{
                            getTextConfirmationAfterInBearbeitungInstitutionStufe2(
                                rueckforderungFormular
                            )
                        }}
                    </div>
                </div>
                <fieldset>
                    <!-- Stufe 1 Institution -->
                    <div
                        class="form-group"
                        *ngIf="
                            isInstitutionStufe1(rueckforderungFormular) ||
                            isInstitutionStufe1ReadOnly(rueckforderungFormular)
                        "
                    >
                        <div
                            class="row"
                            *ngIf="isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        for="anzahl_plaetze_stufe1_insti_in_tage_id"
                                    >
                                        {{
                                            'RUECKFORDERUNGFORMULAR_ANZAHL_PLAETZE'
                                                | translate
                                        }}</label
                                    >
                                    <input
                                        id="anzahl_plaetze_stufe1_insti_in_tage_id"
                                        type="number"
                                        name="anzahl_plaetze_stufe1_insti_in_tage"
                                        #stufe1InstitutionKostenuebernahmeAnzahlTage="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                        "
                                        class="form-control"
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        [disabled]="readOnly"
                                        required
                                    />
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1InstitutionKostenuebernahmeAnzahlTage.errors
                                        "
                                        inputId="anzahl_plaetze_stufe1_insti_in_tage_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row"
                            *ngIf="!isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        for="anzahl_stunden_stufe1_insti_id"
                                    >
                                        {{
                                            'RUECKFORDERUNGFORMULAR_ANZAHL_STUNDEN'
                                                | translate
                                        }}</label
                                    >
                                    <input
                                        id="anzahl_stunden_stufe1_insti_id"
                                        type="number"
                                        name="anzahl_stunden_stufe1_insti"
                                        #stufe1InstitutionKostenuebernahmeAnzahlStunden="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                        "
                                        class="form-control"
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        [disabled]="readOnly"
                                        required
                                    />
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1InstitutionKostenuebernahmeAnzahlStunden.errors
                                        "
                                        inputId="anzahl_stunden_stufe1_insti_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        for="kostenuebernahme_betreuung_stuf1_insti_id"
                                    >
                                        {{
                                            'RUECKFORDERUNGFORMULAR_KOSTENUEBERNAHME_BETREUUNG'
                                                | translate
                                        }}</label
                                    >
                                    <input
                                        id="kostenuebernahme_betreuung_stuf1_insti_id"
                                        type="number"
                                        name="kostenuebernahme_betreuung_stuf1_insti"
                                        #stufe1InstitutionKostenuebernahmeBetreuung="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                        "
                                        class="form-control"
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        [disabled]="readOnly"
                                        required
                                    />
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1InstitutionKostenuebernahmeBetreuung.errors
                                        "
                                        inputId="kostenuebernahme_betreuung_stuf1_insti_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <!-- ProvisorischeBerechnung -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        translate
                                        for="kostenuebernahme_prov_betrag_stuf1_insti_id"
                                    >
                                        {{
                                            'RUECKFORDERUNGFORMULAR_PROVISORISCHE_BETRAG'
                                                | translate
                                        }}</label
                                    >
                                    <input
                                        id="kostenuebernahme_prov_betrag_stuf1_insti_id"
                                        type="number"
                                        name="kostenuebernahme_prov_betrag_stuf1_insti"
                                        [(ngModel)]="provisorischerBetrag"
                                        class="form-control"
                                        disabled="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Stufe 1 Kanton -->
                    <div
                        class="form-group"
                        *ngIf="isPruefungKantonStufe1(rueckforderungFormular)"
                    >
                        <div
                            class="row"
                            *ngIf="isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        translate
                                        for="anzahl_plaetze_stufe1_kanton_in_tage_id"
                                    >
                                        RUECKFORDERUNGFORMULAR_ANZAHL_PLAETZE</label
                                    >
                                    <input
                                        id="anzahl_plaetze_stufe1_kanton_in_tage_id"
                                        type="number"
                                        name="anzahl_plaetze_stufe1_kanton_in_tage"
                                        #stufe1KantonKostenuebernahmeAnzahlTage="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage
                                        "
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        class="form-control"
                                        required
                                    />
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                            }}</span>
                                        </div>
                                    </div>
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1KantonKostenuebernahmeAnzahlTage.errors
                                        "
                                        inputId="anzahl_plaetze_stufe1_kanton_in_tage_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row"
                            *ngIf="!isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        translate
                                        for="anzahl_stunden_stufe1_kanton_id"
                                    >
                                        RUECKFORDERUNGFORMULAR_ANZAHL_STUNDEN</label
                                    >
                                    <input
                                        id="anzahl_stunden_stufe1_kanton_id"
                                        type="number"
                                        name="anzahl_stunden_stufe1_kanton"
                                        #stufe1KantonKostenuebernahmeAnzahlStunden="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden
                                        "
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        class="form-control"
                                        required
                                    />
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                            }}</span>
                                        </div>
                                    </div>
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1KantonKostenuebernahmeAnzahlStunden.errors
                                        "
                                        inputId="anzahl_stunden_stufe1_kanton_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        translate
                                        for="kostenuebernahme_betreuung_stuf1_kanton_id"
                                    >
                                        RUECKFORDERUNGFORMULAR_KOSTENUEBERNAHME_BETREUUNG</label
                                    >
                                    <input
                                        id="kostenuebernahme_betreuung_stuf1_kanton_id"
                                        type="number"
                                        name="kostenuebernahme_betreuung_stuf1_kanton"
                                        #stufe1KantonKostenuebernahmeBetreuung="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung
                                        "
                                        (blur)="
                                            calculateProvBetrag(
                                                rueckforderungFormular
                                            )
                                        "
                                        class="form-control"
                                        required
                                    />
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                            }}</span>
                                        </div>
                                    </div>
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe1KantonKostenuebernahmeBetreuung.errors
                                        "
                                        inputId="kostenuebernahme_betreuung_stuf1_kanton_id"
                                    >
                                    </dv-error-messages>
                                </div>
                            </div>
                        </div>
                        <!-- ProvisorischeBerechnung -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label
                                        class="required long-label"
                                        translate
                                        for="kostenuebernahme_prov_betrag_stuf1_kanton_id"
                                    >
                                        RUECKFORDERUNGFORMULAR_PROVISORISCHE_BETRAG</label
                                    >
                                    <input
                                        id="kostenuebernahme_prov_betrag_stuf1_kanton_id"
                                        type="number"
                                        name="kostenuebernahme_prov_betrag_stuf1_kanton"
                                        [(ngModel)]="provisorischerBetrag"
                                        class="form-control"
                                        disabled="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group"
                        *ngIf="
                            isGeprueftKantonStufe1(rueckforderungFormular) ||
                            isInstitutionStufe2ForKantonReadOnly(
                                rueckforderungFormular
                            )
                        "
                    >
                        <div
                            class="row"
                            *ngIf="isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <h3 class="required" translate>
                                        RUECKFORDERUNGFORMULAR_ANZAHL_PLAETZE
                                    </h3>
                                    <p>
                                        {{
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage
                                        }}
                                    </p>
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row"
                            *ngIf="!isKitaAngebot(rueckforderungFormular)"
                        >
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <h3 class="required" translate>
                                        RUECKFORDERUNGFORMULAR_ANZAHL_STUNDEN
                                    </h3>
                                    <p>
                                        {{
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden
                                        }}
                                    </p>
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <h3 class="required" translate>
                                        RUECKFORDERUNGFORMULAR_KOSTENUEBERNAHME_BETREUUNG
                                    </h3>
                                    <p>
                                        {{
                                            rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung
                                        }}
                                    </p>
                                    <div class="bisher-form">
                                        <div
                                            class="dv-bisher"
                                            *ngIf="
                                                rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung !==
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                            "
                                        >
                                            <span translate
                                                >BISHER_INSTITUTION_STUFE1</span
                                            >
                                            <span>{{
                                                rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Stufe 2 Institution und Kanton -->
                    <div
                        class="form-group"
                        *ngIf="
                            isInstitutionStufe2(rueckforderungFormular) ||
                            isPruefungKantonStufe2(rueckforderungFormular) ||
                            isInstitutionStufe2ReadOnly(
                                rueckforderungFormular
                            ) ||
                            isKantonStufe2ReadOnly(rueckforderungFormular)
                        "
                    >
                        <label class="required long-label">
                            {{
                                'RUECKFORDERUNGFORMULAR_INSTITUTION_TYP'
                                    | translate
                            }}</label
                        >
                        <mat-radio-group
                            name="institutionTyp"
                            #institutionTyp="ngModel"
                            [(ngModel)]="rueckforderungFormular.institutionTyp"
                            [disabled]="readOnly"
                            required
                        >
                            <mat-radio-button
                                [value]="
                                    getRueckforderungInstitutionTypOffentlich()
                                "
                                (blur)="
                                    calculateProvBetrag(rueckforderungFormular)
                                "
                                >{{
                                    'INSTITUTION_TYP_OFFENTLICH' | translate
                                }}</mat-radio-button
                            >
                            &nbsp;&nbsp;
                            <mat-radio-button
                                [value]="
                                    getRueckforderungInstitutionTypPrivat()
                                "
                                (blur)="
                                    calculateProvBetrag(rueckforderungFormular)
                                "
                                >{{ 'INSTITUTION_TYP_PRIVAT' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                        <dv-error-messages
                            *ngIf="form.submitted"
                            [errorObject]="institutionTyp.errors"
                            inputId="institutionTyp_id"
                        >
                        </dv-error-messages>
                        <div
                            *ngIf="
                                rueckforderungFormular.institutionTyp ===
                                getRueckforderungInstitutionTypOffentlich()
                            "
                        >
                            <!-- Stufe 2 Institution offentlich -->
                            <div
                                *ngIf="
                                    isInstitutionStufe2(
                                        rueckforderungFormular
                                    ) ||
                                    isInstitutionStufe2ReadOnly(
                                        rueckforderungFormular
                                    )
                                "
                            >
                                <div
                                    class="row"
                                    *ngIf="
                                        isKitaAngebot(rueckforderungFormular)
                                    "
                                >
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label margin-top-30"
                                                for="anzahl_plaetze_stufe2_insti_in_tage_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_ANZAHL_PLAETZE'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="anzahl_plaetze_stufe2_insti_in_tage_id"
                                                type="number"
                                                name="anzahl_plaetze_stufe2_insti_in_tage"
                                                #stufe2InstitutionKostenuebernahmeAnzahlTage="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlTage
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlTage
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlTage
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2InstitutionKostenuebernahmeAnzahlTage.errors
                                                "
                                                inputId="anzahl_plaetze_stufe2_insti_in_tage_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    *ngIf="
                                        !isKitaAngebot(rueckforderungFormular)
                                    "
                                >
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label margin-top-30"
                                                for="anzahl_stunden_stufe2_insti_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_ANZAHL_STUNDEN'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="anzahl_stunden_stufe2_insti_id"
                                                type="number"
                                                name="anzahl_stunden_stufe2_insti"
                                                #stufe2InstitutionKostenuebernahmeAnzahlStunden="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlStunden
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlStunden
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlStunden
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2InstitutionKostenuebernahmeAnzahlStunden.errors
                                                "
                                                inputId="anzahl_stunden_stufe2_insti_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="kostenuebernahme_betreuung_stuf2_insti_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_KOSTENUEBERNAHME_BETREUUNG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="kostenuebernahme_betreuung_stuf2_insti_id"
                                                type="number"
                                                name="kostenuebernahme_betreuung_stuf2_insti"
                                                #stufe2InstitutionKostenuebernahmeBetreuung="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2InstitutionKostenuebernahmeBetreuung
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeBetreuung
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung !==
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeBetreuung
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2InstitutionKostenuebernahmeBetreuung.errors
                                                "
                                                inputId="kostenuebernahme_betreuung_stuf2_insti_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <!-- ProvisorischeBerechnung Oeffentliche Institutionen-->
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                translate
                                                for="kostenuebernahme_prov_betrag_stuf2_insti_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_STUFE2_PROVISORISCHE_BETRAG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="kostenuebernahme_prov_betrag_stuf2_insti_id"
                                                type="number"
                                                name="kostenuebernahme_prov_betrag_stuf2_insti"
                                                [(ngModel)]="
                                                    provisorischerBetrag
                                                "
                                                class="form-control"
                                                disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Stufe 2 Kanton offentlich -->
                            <div
                                *ngIf="
                                    isPruefungKantonStufe2(
                                        rueckforderungFormular
                                    ) ||
                                    isKantonStufe2ReadOnly(
                                        rueckforderungFormular
                                    )
                                "
                            >
                                <div
                                    class="row"
                                    *ngIf="
                                        isKitaAngebot(rueckforderungFormular)
                                    "
                                >
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label margin-top-30"
                                                for="anzahl_plaetze_stufe2_kanton_in_tage_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_ANZAHL_PLAETZE'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="anzahl_plaetze_stufe2_kanton_in_tage_id"
                                                type="number"
                                                name="anzahl_plaetze_stufe2_kanton_in_tage"
                                                #stufe2KantonKostenuebernahmeAnzahlTage="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlTage
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlTage
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlTage
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlTage
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlTage
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlTage !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlTage
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE2</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlTage
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2KantonKostenuebernahmeAnzahlTage.errors
                                                "
                                                inputId="anzahl_plaetze_stufe2_kanton_in_tage_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    *ngIf="
                                        !isKitaAngebot(rueckforderungFormular)
                                    "
                                >
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label margin-top-30"
                                                for="anzahl_stunden_stufe2_kanton_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_ANZAHL_STUNDEN'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="anzahl_stunden_stufe2_kanton_id"
                                                type="number"
                                                name="anzahl_stunden_stufe2_kanton"
                                                #stufe2KantonKostenuebernahmeAnzahlStunden="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlStunden
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlStunden
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeAnzahlStunden
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlStunden
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeAnzahlStunden
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlStunden !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeAnzahlStunden
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE2</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeAnzahlStunden
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2KantonKostenuebernahmeAnzahlStunden.errors
                                                "
                                                inputId="anzahl_stunden_stufe2_kanton_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="kostenuebernahme_betreuung_stuf2_kanton_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_KOSTENUEBERNAHME_BETREUUNG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="kostenuebernahme_betreuung_stuf2_kanton_id"
                                                type="number"
                                                name="kostenuebernahme_betreuung_stuf2_kanton"
                                                #stufe2KantonKostenuebernahmeBetreuung="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.stufe2KantonKostenuebernahmeBetreuung
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <div class="bisher-form">
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeBetreuung
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE1</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1InstitutionKostenuebernahmeBetreuung
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeBetreuung
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_KANTON</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe1KantonKostenuebernahmeBetreuung
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="dv-bisher"
                                                    *ngIf="
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeBetreuung !==
                                                        rueckforderungFormular.stufe2KantonKostenuebernahmeBetreuung
                                                    "
                                                >
                                                    <span translate
                                                        >BISHER_INSTITUTION_STUFE2</span
                                                    >
                                                    <span>{{
                                                        rueckforderungFormular.stufe2InstitutionKostenuebernahmeBetreuung
                                                    }}</span>
                                                </div>
                                            </div>
                                            <dv-error-messages
                                                [errorObject]="
                                                    stufe2KantonKostenuebernahmeBetreuung.errors
                                                "
                                                inputId="kostenuebernahme_betreuung_stuf2_kanton_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <!-- Provisorische Berechnung öffentliche Institutionen -->
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                translate
                                                for="kostenuebernahme_prov_betrag_stuf2_kanton_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_STUFE2_PROVISORISCHE_BETRAG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="kostenuebernahme_prov_betrag_stuf2_kanton_id"
                                                type="number"
                                                name="kostenuebernahme_prov_betrag_stuf2_kanton"
                                                [(ngModel)]="
                                                    provisorischerBetrag
                                                "
                                                class="form-control"
                                                disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Stufe 2 Privat Institution und Kanton -->
                        <div
                            *ngIf="
                                rueckforderungFormular.institutionTyp ===
                                getRueckforderungInstitutionTypPrivat()
                            "
                        >
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            class="required long-label margin-top-30"
                                            for="elternbeitraege_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_ELTERNBEITRAEGE'
                                                    | translate
                                            }}</label
                                        >
                                        <input
                                            id="elternbeitraege_stufe2_privat_id"
                                            type="number"
                                            name="elternbeitraege_stufe2_privat"
                                            #betragEntgangeneElternbeitraege="ngModel"
                                            [(ngModel)]="
                                                rueckforderungFormular.betragEntgangeneElternbeitraege
                                            "
                                            class="form-control"
                                            [disabled]="readOnly"
                                            (blur)="
                                                calculateProvBetrag(
                                                    rueckforderungFormular
                                                )
                                            "
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                betragEntgangeneElternbeitraege.errors
                                            "
                                            inputId="betragEntgangeneElternbeitraege_id"
                                        >
                                        </dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            *ngIf="
                                                isKitaAngebot(
                                                    rueckforderungFormular
                                                )
                                            "
                                            class="required long-label"
                                            for="nichtAngeboteneEinheiten_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_NICHT_ANGEBOTENE_TAGE'
                                                    | translate
                                            }}</label
                                        >
                                        <label
                                            *ngIf="
                                                !isKitaAngebot(
                                                    rueckforderungFormular
                                                )
                                            "
                                            class="required long-label"
                                            for="nichtAngeboteneEinheiten_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_NICHT_ANGEBOTENE_STUNDEN'
                                                    | translate
                                            }}</label
                                        >
                                        <input
                                            id="nichtAngeboteneEinheiten_stufe2_privat_id"
                                            type="number"
                                            name="nichtAngeboteneEinheiten_stufe2_privat"
                                            #betragEntgangeneElternbeitraegeNichtAngeboteneEinheiten="ngModel"
                                            [(ngModel)]="
                                                rueckforderungFormular.betragEntgangeneElternbeitraegeNichtAngeboteneEinheiten
                                            "
                                            class="form-control"
                                            [disabled]="readOnly"
                                            (blur)="
                                                calculateProvBetrag(
                                                    rueckforderungFormular
                                                )
                                            "
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                betragEntgangeneElternbeitraegeNichtAngeboteneEinheiten.errors
                                            "
                                            inputId="betragEntgangeneElternbeitraegeNichtAngeboteneEinheiten_id"
                                        >
                                        </dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            *ngIf="
                                                isKitaAngebot(
                                                    rueckforderungFormular
                                                )
                                            "
                                            class="required long-label"
                                            for="anzahlNichtAngeboteneEinheiten_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_ANZAHL_NICHT_ANGEBOTENE_TAGE'
                                                    | translate
                                            }}</label
                                        >
                                        <label
                                            *ngIf="
                                                !isKitaAngebot(
                                                    rueckforderungFormular
                                                )
                                            "
                                            class="required long-label"
                                            for="anzahlNichtAngeboteneEinheiten_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_ANZAHL_NICHT_ANGEBOTENE_STUNDEN'
                                                    | translate
                                            }}</label
                                        >
                                        <input
                                            id="anzahlNichtAngeboteneEinheiten_stufe2_privat_id"
                                            type="number"
                                            name="anzahlNichtAngeboteneEinheiten_stufe2_privat"
                                            #anzahlNichtAngeboteneEinheiten="ngModel"
                                            [(ngModel)]="
                                                rueckforderungFormular.anzahlNichtAngeboteneEinheiten
                                            "
                                            class="form-control"
                                            [disabled]="readOnly"
                                            (blur)="
                                                calculateProvBetrag(
                                                    rueckforderungFormular
                                                )
                                            "
                                            required
                                        />
                                        <dv-error-messages
                                            [errorObject]="
                                                anzahlNichtAngeboteneEinheiten.errors
                                            "
                                            inputId="anzahlNichtAngeboteneEinheiten_id"
                                        >
                                        </dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            class="required long-label bold-label"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_KURZARBEIT_BEANTRAGT'
                                                    | translate
                                            }}</label
                                        >
                                        <mat-radio-group
                                            name="kurzarbeitBeantragt"
                                            #kurzarbeitBeantragt="ngModel"
                                            [(ngModel)]="
                                                rueckforderungFormular.kurzarbeitBeantragt
                                            "
                                            [disabled]="readOnly"
                                            (ngModelChange)="
                                                calculateProvBetrag(
                                                    rueckforderungFormular
                                                )
                                            "
                                            required
                                        >
                                            <mat-radio-button [value]="true">{{
                                                'LABEL_JA' | translate
                                            }}</mat-radio-button>
                                            &nbsp;&nbsp;
                                            <mat-radio-button [value]="false"
                                                >{{ 'LABEL_NEIN' | translate }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <dv-error-messages
                                            *ngIf="form.submitted"
                                            [errorObject]="
                                                kurzarbeitBeantragt.errors
                                            "
                                            inputId="kurzarbeitBeantragt_id"
                                        >
                                        </dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    rueckforderungFormular.kurzarbeitBeantragt
                                "
                            >
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="kurzarbeitBetrag_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_KURZARBEIT_BETRAG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="kurzarbeitBetrag_stufe2_privat_id"
                                                type="number"
                                                name="kurzarbeitBetrag_stufe2_privat"
                                                #kurzarbeitBetrag="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.kurzarbeitBetrag
                                                "
                                                class="form-control"
                                                [disabled]="readOnly"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    kurzarbeitBetrag.errors
                                                "
                                                inputId="kurzarbeitBetrag_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="required long-label">
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_FRAGE_DEFINITIV_VERFUEGT'
                                                        | translate
                                                }}</label
                                            >
                                            <mat-radio-group
                                                name="kurzarbeitDefinitivVerfuegt"
                                                #kurzarbeitDefinitivVerfuegt="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.kurzarbeitDefinitivVerfuegt
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                                <mat-radio-button
                                                    [value]="true"
                                                    >{{
                                                        'LABEL_JA' | translate
                                                    }}</mat-radio-button
                                                >
                                                &nbsp;&nbsp;
                                                <mat-radio-button
                                                    [value]="false"
                                                    >{{
                                                        'LABEL_NEIN' | translate
                                                    }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <dv-error-messages
                                                *ngIf="form.submitted"
                                                [errorObject]="
                                                    kurzarbeitDefinitivVerfuegt.errors
                                                "
                                                inputId="kurzarbeitDefinitivVerfuegt_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    !rueckforderungFormular.kurzarbeitBeantragt &&
                                    rueckforderungFormular.kurzarbeitBeantragt !==
                                        null
                                "
                            >
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="long-label">
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_BERUEUNDUNG_INFO'
                                                        | translate
                                                }}</label
                                            >

                                            <label
                                                class="required long-label"
                                                for="kurzarbeitKeinAntragBegruendung_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_KEINANTRAG_BEGRUENDUNG'
                                                        | translate
                                                }}</label
                                            >
                                            <textarea
                                                md-no-autogrow
                                                class="form-control"
                                                rows="6"
                                                maxlength="2000"
                                                id="kurzarbeitKeinAntragBegruendung_stufe2_privat_id"
                                                #kurzarbeitKeinAntragBegruendung="ngModel"
                                                name="kurzarbeitKeinAntragBegruendung_stufe2_privat"
                                                [(ngModel)]="
                                                    rueckforderungFormular.kurzarbeitKeinAntragBegruendung
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                            </textarea>
                                            <dv-error-messages
                                                [errorObject]="
                                                    kurzarbeitKeinAntragBegruendung.errors
                                                "
                                                inputId="kurzarbeitKeinAntragBegruendung_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="kurzarbeitSonstiges_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_SONSTIGES_BEGRUENDUNG'
                                                        | translate
                                                }}</label
                                            >
                                            <textarea
                                                md-no-autogrow
                                                class="form-control"
                                                rows="6"
                                                maxlength="2000"
                                                id="kurzarbeitSonstiges_stufe2_privat_id"
                                                #kurzarbeitSonstiges="ngModel"
                                                name="kurzarbeitSonstiges_stufe2_privat"
                                                [(ngModel)]="
                                                    rueckforderungFormular.kurzarbeitSonstiges
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                            </textarea>
                                            <dv-error-messages
                                                [errorObject]="
                                                    kurzarbeitSonstiges.errors
                                                "
                                                inputId="kurzarbeitSonstiges_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label
                                            class="required long-label bold-label"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_CORONA_ERWERBSERSATZ_BEANTRAGT'
                                                    | translate
                                            }}</label
                                        >
                                        <mat-radio-group
                                            name="coronaErwerbsersatzBeantragt"
                                            #coronaErwerbsersatzBeantragt="ngModel"
                                            [(ngModel)]="
                                                rueckforderungFormular.coronaErwerbsersatzBeantragt
                                            "
                                            [disabled]="readOnly"
                                            (ngModelChange)="
                                                calculateProvBetrag(
                                                    rueckforderungFormular
                                                )
                                            "
                                            required
                                        >
                                            <mat-radio-button [value]="true">{{
                                                'LABEL_JA' | translate
                                            }}</mat-radio-button>
                                            &nbsp;&nbsp;
                                            <mat-radio-button [value]="false"
                                                >{{ 'LABEL_NEIN' | translate }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <dv-error-messages
                                            *ngIf="form.submitted"
                                            [errorObject]="
                                                coronaErwerbsersatzBeantragt.errors
                                            "
                                            inputId="coronaErwerbsersatzBeantrag_id"
                                        >
                                        </dv-error-messages>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    rueckforderungFormular.coronaErwerbsersatzBeantragt
                                "
                            >
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="coronaErwerbsersatzBetrag_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_CORONA_ERWERBSERSATZ_BETRAG'
                                                        | translate
                                                }}</label
                                            >
                                            <input
                                                id="coronaErwerbsersatzBetrag_stufe2_privat_id"
                                                type="number"
                                                name="coronaErwerbsersatzBetrag_stufe2_privat"
                                                #coronaErwerbsersatzBetrag="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.coronaErwerbsersatzBetrag
                                                "
                                                class="form-control"
                                                (blur)="
                                                    calculateProvBetrag(
                                                        rueckforderungFormular
                                                    )
                                                "
                                                [disabled]="readOnly"
                                                required
                                            />
                                            <dv-error-messages
                                                [errorObject]="
                                                    coronaErwerbsersatzBetrag.errors
                                                "
                                                inputId="coronaErwerbsersatzBetrag_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="required long-label">
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_FRAGE_DEFINITIV_VERFUEGT'
                                                        | translate
                                                }}</label
                                            >
                                            <mat-radio-group
                                                name="coronaErwerbsersatzDefinitivVerfuegt"
                                                #coronaErwerbsersatzDefinitivVerfuegt="ngModel"
                                                [(ngModel)]="
                                                    rueckforderungFormular.coronaErwerbsersatzDefinitivVerfuegt
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                                <mat-radio-button
                                                    [value]="true"
                                                    >{{
                                                        'LABEL_JA' | translate
                                                    }}</mat-radio-button
                                                >
                                                &nbsp;&nbsp;
                                                <mat-radio-button
                                                    [value]="false"
                                                    >{{
                                                        'LABEL_NEIN' | translate
                                                    }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <dv-error-messages
                                                *ngIf="form.submitted"
                                                [errorObject]="
                                                    coronaErwerbsersatzDefinitivVerfuegt.errors
                                                "
                                                inputId="coronaErwerbsersatzDefinitivVerfuegt_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    !rueckforderungFormular.coronaErwerbsersatzBeantragt &&
                                    rueckforderungFormular.coronaErwerbsersatzBeantragt !==
                                        null
                                "
                            >
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="long-label">
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_BERUEUNDUNG_INFO'
                                                        | translate
                                                }}</label
                                            >

                                            <label
                                                class="required long-label"
                                                for="coronaErwerbsersatzKeinAntragBegruendung_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_KEINANTRAG_BEGRUENDUNG'
                                                        | translate
                                                }}</label
                                            >
                                            <textarea
                                                md-no-autogrow
                                                class="form-control"
                                                rows="6"
                                                maxlength="2000"
                                                id="coronaErwerbsersatzKeinAntragBegruendung_stufe2_privat_id"
                                                #coronaErwerbsersatzKeinAntragBegruendung="ngModel"
                                                name="coronaErwerbsersatzKeinAntragBegruendung_stufe2_privat"
                                                [(ngModel)]="
                                                    rueckforderungFormular.coronaErwerbsersatzKeinAntragBegruendung
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                            </textarea>
                                            <dv-error-messages
                                                [errorObject]="
                                                    coronaErwerbsersatzKeinAntragBegruendung.errors
                                                "
                                                inputId="coronaErwerbsersatzKeinAntragBegruendung_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label
                                                class="required long-label"
                                                for="coronaErwerbsersatzSonstiges_stufe2_privat_id"
                                            >
                                                {{
                                                    'RUECKFORDERUNGFORMULAR_SONSTIGES_BEGRUENDUNG'
                                                        | translate
                                                }}</label
                                            >

                                            <textarea
                                                md-no-autogrow
                                                class="form-control"
                                                rows="6"
                                                maxlength="2000"
                                                id="coronaErwerbsersatzSonstiges_stufe2_privat_id"
                                                #coronaErwerbsersatzSonstiges="ngModel"
                                                name="coronaErwerbsersatzSonstiges_stufe2_privat"
                                                [(ngModel)]="
                                                    rueckforderungFormular.coronaErwerbsersatzSonstiges
                                                "
                                                [disabled]="readOnly"
                                                required
                                            >
                                            </textarea>
                                            <dv-error-messages
                                                [errorObject]="
                                                    coronaErwerbsersatzSonstiges.errors
                                                "
                                                inputId="coronaErwerbsersatzSonstiges_id"
                                            >
                                            </dv-error-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!--provisorischer betrag private Institution-->
                                <div class="col-sm-6 mt-5">
                                    <div class="form-group">
                                        <label
                                            class="required long-label"
                                            translate
                                            for="prov_betrag_stufe2_privat_id"
                                        >
                                            {{
                                                'RUECKFORDERUNGFORMULAR_STUFE2_PROVISORISCHE_BETRAG'
                                                    | translate
                                            }}</label
                                        >
                                        <input
                                            id="prov_betrag_stufe2_privat_id"
                                            type="number"
                                            name="prov_betrag_stufe2_privat"
                                            [(ngModel)]="provisorischerBetrag"
                                            class="form-control"
                                            disabled="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Gewaehrte Ausfallentschaedigung -->
                        <div class="row">
                            <div class="col-sm-6 mt-3">
                                <div
                                    class="form-group"
                                    *ngIf="
                                        showKantonStufe2Felder(
                                            rueckforderungFormular
                                        )
                                    "
                                >
                                    <label
                                        class="required long-label margin-top-30"
                                        for="gewaehrte_ausfallentschaedigung_stufe2_id"
                                    >
                                        {{
                                            'RUECKFORDERUNGFORMULAR_GEWAEHRTE_AUSFALLENTSCHAEDIGUNG'
                                                | translate
                                        }}</label
                                    >
                                    <input
                                        id="gewaehrte_ausfallentschaedigung_stufe2_id"
                                        type="number"
                                        name="gewaehrte_ausfallentschaedigung_stufe2"
                                        #stufe2VerfuegungBetrag="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.stufe2VerfuegungBetrag
                                        "
                                        class="form-control"
                                        customMinMax
                                        [minValue]="0"
                                        [required]="
                                            showMessageFehlendeVerfuegungBetrag
                                        "
                                        [disabled]="
                                            !canEditKantonStufe2Felder(
                                                rueckforderungFormular
                                            )
                                        "
                                    />
                                    <dv-error-messages
                                        [errorObject]="
                                            stufe2VerfuegungBetrag.errors
                                        "
                                        inputId="stufe2VerfuegungBetrag_id"
                                    >
                                    </dv-error-messages>
                                    <label
                                        class="required long-label margin-top-30"
                                    >
                                        {{
                                            'KORRESPONDENZSPRACHE' | translate
                                        }}</label
                                    >
                                    <mat-radio-group
                                        name="korrespondenzSprache"
                                        #korrespondenzSprache="ngModel"
                                        [(ngModel)]="
                                            rueckforderungFormular.korrespondenzSprache
                                        "
                                        [disabled]="
                                            !canEditKantonStufe2Felder(
                                                rueckforderungFormular
                                            )
                                        "
                                        required
                                    >
                                        <mat-radio-button [value]="'DEUTSCH'">{{
                                            'DEUTSCH' | translate
                                        }}</mat-radio-button>
                                        &nbsp;&nbsp;
                                        <mat-radio-button
                                            [value]="'FRANZOESISCH'"
                                            >{{ 'FRANZOESISCH' | translate }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="isInstitutionStufe1(rueckforderungFormular)"
                    >
                        <!-- Checkbox -->
                        <div class="col-sm-12">
                            <div class="row mt-5">
                                <div class="col-sm-12">
                                    <span class="mt-5">
                                        {{
                                            'BETREUUNG_KORREKT_AUSGEWIESEN'
                                                | translate
                                        }}
                                        <dv-tooltip
                                            id="betreuungKorrektAusgewiesenCbx-tooltip"
                                            [text]="
                                                'BETREUUNG_KORREKT_AUSGEWIESEN_INFO'
                                                    | translate
                                            "
                                        ></dv-tooltip>
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="betreuungKorrektAusgewiesenCbx_id"
                                            name="betreuungKorrektAusgewiesenCbx_id"
                                            [(ngModel)]="
                                                betreuungKorrektAusgewiesen
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span class="mt-5">
                                        {{
                                            'GUTSCHEIN_PLAETZEN_REDUZIERT'
                                                | translate
                                        }}
                                        <dv-tooltip
                                            id="gutscheinPlaetzenReduziertCbx-tooltip"
                                            [text]="
                                                'GUTSCHEIN_PLAETZEN_REDUZIERT_INFO'
                                                    | translate
                                            "
                                        ></dv-tooltip>
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="gutscheinPlaetzenReduziertCbx_id"
                                            name="gutscheinPlaetzenReduziertCbx_id"
                                            [(ngModel)]="
                                                gutscheinPlaetzenReduziert
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span class="mt-5">
                                        {{
                                            'ERSTATTUNG_GEMAESS_KANTON'
                                                | translate
                                        }}
                                        <dv-tooltip
                                            id="erstattungGemaessKantonCbx-tooltip"
                                            [text]="
                                                'ERSTATTUNG_GEMAESS_KANTON_INFO'
                                                    | translate
                                            "
                                        ></dv-tooltip>
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="erstattungGemaessKantonCbx_id"
                                            name="erstattungGemaessKantonCbx_id"
                                            [(ngModel)]="
                                                erstattungGemaessKanton
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span class="mt-5">
                                        {{
                                            'MAHLZEITEN_BG_SUBVENTIONEN_GEBUEHRENSYSTEM'
                                                | translate
                                        }}
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="mahlzeitenBGSubventionenGebuehrensystemCbx_id"
                                            name="mahlzeitenBGSubventionenGebuehrensystemCbx_id"
                                            [(ngModel)]="
                                                mahlzeitenBGSubventionenGebuehrensystem
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span class="mt-5">
                                        {{
                                            'BELEGE_EINREICHEN_BETRAG_KANTON_ZURUECKFORDERN'
                                                | translate
                                        }}
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="belegeEinreichenBetrageKantonZurueckfordernCbx_id"
                                            name="belegeEinreichenBetrageKantonZurueckfordernCbx_id"
                                            [(ngModel)]="
                                                belegeEinreichenBetrageKantonZurueckfordern
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Checkbox -->
                    <div
                        class="row"
                        *ngIf="
                            isGeprueftKantonStufe1(rueckforderungFormular) ||
                            isPruefungKantonStufe1(rueckforderungFormular) ||
                            isInstitutionStufe1ReadOnly(
                                rueckforderungFormular
                            ) ||
                            isInstitutionStufe2ForKantonReadOnly(
                                rueckforderungFormular
                            )
                        "
                    >
                        <div class="col-sm-12">
                            <span class="mt-5"
                                >{{
                                    'BETREUUNG_KORREKT_AUSGEWIESEN' | translate
                                }}
                                <dv-tooltip
                                    id="betreuungKorrektAusgewiesenCbxReadOnly-tooltip"
                                    [text]="
                                        'BETREUUNG_KORREKT_AUSGEWIESEN_INFO'
                                            | translate
                                    "
                                ></dv-tooltip
                            ></span>
                            <div class="form-group">
                                <mat-checkbox
                                    id="betreuungKorrektAusgewiesenCbxReadOnly_id"
                                    name="betreuungKorrektAusgewiesenCbx_id"
                                    checked="true"
                                    disabled="true"
                                >
                                    {{ 'BESTAETIGEN' | translate }}
                                </mat-checkbox>
                            </div>
                            <span class="mt-5"
                                >{{
                                    'GUTSCHEIN_PLAETZEN_REDUZIERT' | translate
                                }}
                                <dv-tooltip
                                    id="gutscheinPlaetzenReduziertCbxReadOnly-tooltip"
                                    [text]="
                                        'GUTSCHEIN_PLAETZEN_REDUZIERT_INFO'
                                            | translate
                                    "
                                ></dv-tooltip
                            ></span>
                            <div class="form-group">
                                <mat-checkbox
                                    id="gutscheinPlaetzenReduziertCbxReadOnly_id"
                                    name="gutscheinPlaetzenReduziertCbx_id"
                                    checked="true"
                                    disabled="true"
                                >
                                    {{ 'BESTAETIGEN' | translate }}
                                </mat-checkbox>
                            </div>
                            <span class="mt-5"
                                >{{ 'ERSTATTUNG_GEMAESS_KANTON' | translate }}
                                <dv-tooltip
                                    id="erstattungGemaessKantonCbxReadOnly-tooltip"
                                    [text]="
                                        'ERSTATTUNG_GEMAESS_KANTON_INFO'
                                            | translate
                                    "
                                >
                                </dv-tooltip>
                            </span>
                            <div class="form-group">
                                <mat-checkbox
                                    id="erstattungGemaessKantonCbxReadOnly_id"
                                    name="erstattungGemaessKantonCbx_id"
                                    checked="true"
                                    disabled="true"
                                >
                                    {{ 'BESTAETIGEN' | translate }}
                                </mat-checkbox>
                            </div>
                            <span class="mt-5">{{
                                'MAHLZEITEN_BG_SUBVENTIONEN_GEBUEHRENSYSTEM'
                                    | translate
                            }}</span>
                            <div class="form-group">
                                <mat-checkbox
                                    id="mahlzeitenBGSubventionenGebuehrensystemCbxReadOnly_id"
                                    name="mahlzeitenBGSubventionenGebuehrensystemCbx_id"
                                    checked="true"
                                    disabled="true"
                                >
                                    {{ 'BESTAETIGEN' | translate }}
                                </mat-checkbox>
                            </div>
                            <span class="mt-5">
                                {{
                                    'BELEGE_EINREICHEN_BETRAG_KANTON_ZURUECKFORDERN'
                                        | translate
                                }}
                            </span>
                            <div class="form-group">
                                <mat-checkbox
                                    id="belegeEinreichenBetrageKantonZurueckfordernCbxReadOnly_id"
                                    name="belegeEinreichenBetrageKantonZurueckfordernCbx_id"
                                    checked="true"
                                    disabled="true"
                                >
                                    {{ 'BESTAETIGEN' | translate }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="isInstitutionStufe2(rueckforderungFormular)"
                    >
                        <!-- Checkbox -->
                        <div class="col-sm-12">
                            <div
                                class="row mt-5"
                                *ngIf="
                                    rueckforderungFormular.institutionTyp ===
                                    getRueckforderungInstitutionTypOffentlich()
                                "
                            >
                                <div class="col-sm-12">
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'ELTERN_BEITRAGE_NICHT_IN_RECHNUNG_OFFENTLICH'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="elternbeitraegeNichtInRechnungOffentlichCbx_id"
                                            name="elternbeitraegeNichtInRechnungCbx_id"
                                            [(ngModel)]="
                                                elternbeitraegeNichtInRechnung
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'NOTWENDIGEN_INFORMATIONEN_LIEFERN_OFFENTLICH'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="notwendigenInformationenLiefernOffentlichCbx_id"
                                            name="notwendigenInformationenLiefernCbx_id"
                                            [(ngModel)]="
                                                notwendigenInformationenLiefern
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row mt-5"
                                *ngIf="
                                    rueckforderungFormular.institutionTyp ===
                                    getRueckforderungInstitutionTypPrivat()
                                "
                            >
                                <div class="col-sm-12">
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'ELTERN_BEITRAGE_NICHT_IN_RECHNUNG_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="elternbeitraegeNichtInRechnungPrivatCbx_id"
                                            name="elternbeitraegeNichtInRechnungCbx_id"
                                            [(ngModel)]="
                                                elternbeitraegeNichtInRechnung
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'VERPFLEGUNGSKOSTEN_KORREKT_ABGEZOGEN_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="Cbx_id"
                                            name="verpflegungskostenKorrektAbgezogenCbx_id"
                                            [(ngModel)]="
                                                verpflegungskostenKorrektAbgezogen
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'NOTWENDIGEN_INFORMATIONEN_LIEFERN_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="notwendigenInformationenLiefernPrivatCbx_id"
                                            name="notwendigenInformationenLiefernCbx_id"
                                            [(ngModel)]="
                                                notwendigenInformationenLiefern
                                            "
                                            required
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="
                            isPruefungKantonStufe2(rueckforderungFormular) ||
                            isInstitutionStufe2ReadOnly(
                                rueckforderungFormular
                            ) ||
                            isKantonStufe2ReadOnly(rueckforderungFormular)
                        "
                    >
                        <!-- Checkbox -->
                        <div class="col-sm-12">
                            <div class="row mt-5">
                                <div
                                    class="col-sm-12"
                                    *ngIf="
                                        rueckforderungFormular.institutionTyp ===
                                        getRueckforderungInstitutionTypOffentlich()
                                    "
                                >
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'ELTERN_BEITRAGE_NICHT_IN_RECHNUNG_OFFENTLICH'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="elternbeitraegeNichtInRechnungCbxReadOnly_id"
                                            name="elternbeitraegeNichtInRechnungCbxReadOnly_id"
                                            checked="true"
                                            disabled="true"
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'NOTWENDIGEN_INFORMATIONEN_LIEFERN_OFFENTLICH'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="notwendigenInformationenLiefernCbxReadOnly_id"
                                            name="notwendigenInformationenLiefernCbxReadOnly_id"
                                            checked="true"
                                            disabled="true"
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div
                                    class="col-sm-12"
                                    *ngIf="
                                        rueckforderungFormular.institutionTyp ===
                                        getRueckforderungInstitutionTypPrivat()
                                    "
                                >
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'ELTERN_BEITRAGE_NICHT_IN_RECHNUNG_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="elternbeitraegeNichtInRechnungPrivatCbxReadOnly_id"
                                            name="elternbeitraegeNichtInRechnungPrivatCbxReadOnly_id"
                                            checked="true"
                                            disabled="true"
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'VERPFLEGUNGSKOSTEN_KORREKT_ABGEZOGEN_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="verpflegungskostenKorrektAbgezogenPrivatCbxReadOnly_id"
                                            name="verpflegungskostenKorrektAbgezogenPrivatCbxReadOnly_id"
                                            checked="true"
                                            disabled="true"
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        class="mt-5"
                                        [innerHtml]="
                                            'NOTWENDIGEN_INFORMATIONEN_LIEFERN_PRIVAT'
                                                | translate
                                        "
                                    >
                                    </span>
                                    <div class="form-group">
                                        <mat-checkbox
                                            id="notwendigenInformationenLiefernPrivatCbxReadOnly_id"
                                            name="notwendigenInformationenLiefernPrivatCbxReadOnly_id"
                                            checked="true"
                                            disabled="true"
                                        >
                                            {{ 'BESTAETIGEN' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <div
                class="col-md-8 col-md-offset-2"
                *ngIf="showKantonStufe2Felder(rueckforderungFormular)"
            >
                <div class="row">
                    <div class="col-sm-12">
                        <h2 translate class="margin-top-50">
                            RUECKFORDERUNG_FORMULAR_BEMERKUNG_ZUR_VERFUEGUNG_TITLE
                            <dv-tooltip
                                id="bemerkungZurVerfuegungtooltip"
                                [text]="
                                    'RUECKFORDERUNG_FORMULAR_BEMERKUNG_ZUR_VERFUEGUNG_HELP'
                                        | translate
                                "
                            ></dv-tooltip>
                        </h2>
                        <hr class="header" />
                        <label for="bemerkungZurVerfuegung_id"></label>
                        <textarea
                            md-no-autogrow
                            class="form-control"
                            rows="6"
                            maxlength="10000"
                            id="bemerkungZurVerfuegung_id"
                            #bemerkungZurVerfuegung="ngModel"
                            name="bemerkungZurVerfuegung_name"
                            [(ngModel)]="
                                rueckforderungFormular.bemerkungFuerVerfuegung
                            "
                            [disabled]="
                                !canEditKantonStufe2Felder(
                                    rueckforderungFormular
                                )
                            "
                        >
                        </textarea>
                        <dv-error-messages
                            [errorObject]="bemerkungZurVerfuegung.errors"
                            inputId="bemerkungZurVerfuegung_id"
                        >
                        </dv-error-messages>
                        <div
                            class="dv-error-messages"
                            *ngIf="showMessageFehlendeBemerkungen"
                        >
                            <span>{{ 'ERROR_REQUIRED' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8 col-md-offset-2">
                <dv-rueckforderung-mitteilungen
                    [rueckforderungMitteilungen]="
                        rueckforderungFormular.rueckforderungMitteilungen
                    "
                ></dv-rueckforderung-mitteilungen>
            </div>

            <div
                class="col-md-8 col-md-offset-2"
                *ngIf="
                    isGeprueftKantonStufe1(rueckforderungFormular) ||
                    isStufe2(rueckforderungFormular)
                "
            >
                <div class="row">
                    <div class="col-sm-12">
                        <h2 translate class="margin-top-30">
                            RUECKFORDERUNG_ZAHLUNGEN
                        </h2>
                        <div class="table-responsive">
                            <table class="table">
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th translate>
                                            RUECKFORDERUNG_ZAHLUNGEN_ERSTELLT
                                        </th>
                                        <th translate>
                                            RUECKFORDERUNG_ZAHLUNGEN_STUFE
                                        </th>
                                        <th translate>
                                            RUECKFORDERUNG_ZAHLUNGEN_BETRAG
                                        </th>
                                        <th translate>
                                            RUECKFORDERUNG_ZAHLUNGEN_AUSGELOEST
                                        </th>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let zahlung of rueckforderungZahlungenList
                                        "
                                    >
                                        <td>
                                            {{
                                                zahlung.datumErstellt?.format(
                                                    'L LTS'
                                                )
                                            }}
                                        </td>
                                        <td>{{ zahlung.stufe | translate }}</td>
                                        <td>{{ zahlung.betrag }}</td>
                                        <td>
                                            {{
                                                (zahlung.ausgeloest
                                                    ? 'LABEL_JA'
                                                    : 'LABEL_NEIN'
                                                ) | translate
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Dokumente hochladen -->
            <div
                class="col-md-8 col-md-offset-2"
                *ngIf="isStufe2(rueckforderungFormular)"
            >
                <div class="row">
                    <div class="col-sm-12">
                        <h2 class="margin-top-50">
                            <span
                                translate="RUECKFORDERUNG_DOKUMENTE_HOCHLADEN"
                            ></span>
                            &nbsp;
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="{{
                                    'RUECKFORDERUNG_VORLAGEN_LINK' | translate
                                }}"
                                translate
                                >RUECKFORDERUNG_VORLAGEN_LINK_TEXT</a
                            >
                        </h2>
                        <hr class="header" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div
                            class="dv-error-messages"
                            *ngIf="showMessageFehlendeDokumenteAngaben"
                        >
                            <span>{{
                                'RUECKFORDERUNG_FEHLENDE_DOKUMENTE' | translate
                            }}</span>
                        </div>
                        <dv-multiple-file-upload
                            (download)="download($event[0], $event[1])"
                            [title]="
                                getDokumenteAngabenTitle(rueckforderungFormular)
                            "
                            (delete)="delete($event)"
                            (uploadFile)="
                                uploadRuckforderungDokumente(
                                    $event,
                                    rueckforderungFormular.id,
                                    rueckforderungDokumentTyp.ANGABEN_DOKUMENTE
                                )
                            "
                            [files]="rueckforderungAngabenDokumente"
                            [readOnly]="readOnlyDocument"
                            [readOnlyDelete]="
                                !canDeleteDocuments(rueckforderungFormular)
                            "
                        >
                        </dv-multiple-file-upload>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div
                            class="dv-error-messages"
                            *ngIf="showMessageFehlendeDokumenteKommunikation"
                        >
                            <span>{{
                                'RUECKFORDERUNG_FEHLENDE_DOKUMENTE' | translate
                            }}</span>
                        </div>
                        <dv-multiple-file-upload
                            (download)="download($event[0], $event[1])"
                            [title]="
                                getDokumenteKommunikationTitle(
                                    rueckforderungFormular
                                )
                            "
                            (delete)="delete($event)"
                            (uploadFile)="
                                uploadRuckforderungDokumente(
                                    $event,
                                    rueckforderungFormular.id,
                                    rueckforderungDokumentTyp.KOMMUNIKATION_DOKUMENTE
                                )
                            "
                            [files]="rueckforderungKommunikationDokumente"
                            [readOnly]="readOnlyDocument"
                            [readOnlyDelete]="
                                !canDeleteDocuments(rueckforderungFormular)
                            "
                        >
                        </dv-multiple-file-upload>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div
                            class="dv-error-messages"
                            *ngIf="showMessageFehlendeDokumenteEinsatzplaene"
                        >
                            <span>{{
                                'RUECKFORDERUNG_FEHLENDE_DOKUMENTE' | translate
                            }}</span>
                        </div>
                        <dv-multiple-file-upload
                            (download)="download($event[0], $event[1])"
                            [title]="
                                getDokumenteEinsatzplaeneTitle(
                                    rueckforderungFormular
                                )
                            "
                            (delete)="delete($event)"
                            (uploadFile)="
                                uploadRuckforderungDokumente(
                                    $event,
                                    rueckforderungFormular.id,
                                    rueckforderungDokumentTyp.EINSATZPLAENE_DOKUMENTE
                                )
                            "
                            [files]="rueckforderungEinsatzplaeneDokumente"
                            [readOnly]="readOnlyDocument"
                            [readOnlyDelete]="
                                !canDeleteDocuments(rueckforderungFormular)
                            "
                        >
                        </dv-multiple-file-upload>
                    </div>
                </div>
                <div
                    *ngIf="
                        rueckforderungFormular.institutionTyp ===
                        getRueckforderungInstitutionTypPrivat()
                    "
                >
                    <div
                        class="row"
                        *ngIf="
                            rueckforderungFormular.kurzarbeitDefinitivVerfuegt
                        "
                    >
                        <div class="col-sm-12">
                            <div
                                class="dv-error-messages"
                                *ngIf="showMessageFehlendeDokumenteKurzarbeit"
                            >
                                <span>{{
                                    'RUECKFORDERUNG_FEHLENDE_DOKUMENTE'
                                        | translate
                                }}</span>
                            </div>
                            <dv-multiple-file-upload
                                (download)="download($event[0], $event[1])"
                                [title]="'RUECKOFORDERUNG_DOKUMENTE_KURZARBEIT'"
                                (delete)="delete($event)"
                                (uploadFile)="
                                    uploadRuckforderungDokumente(
                                        $event,
                                        rueckforderungFormular.id,
                                        rueckforderungDokumentTyp.KURZARBEIT_DOKUMENTE
                                    )
                                "
                                [files]="rueckforderungKurzarbeitDokumente"
                                [readOnly]="readOnlyDocument"
                                [readOnlyDelete]="
                                    !canDeleteDocuments(rueckforderungFormular)
                                "
                            >
                            </dv-multiple-file-upload>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="
                            rueckforderungFormular.coronaErwerbsersatzDefinitivVerfuegt
                        "
                    >
                        <div class="col-sm-12">
                            <div
                                class="dv-error-messages"
                                *ngIf="
                                    showMessageFehlendeDokumenteErwerbsersatz
                                "
                            >
                                <span>{{
                                    'RUECKFORDERUNG_FEHLENDE_DOKUMENTE'
                                        | translate
                                }}</span>
                            </div>
                            <dv-multiple-file-upload
                                (download)="download($event[0], $event[1])"
                                [title]="
                                    'RUECKOFORDERUNG_DOKUMENTE_ERWERBSERSATZ'
                                "
                                (delete)="delete($event)"
                                (uploadFile)="
                                    uploadRuckforderungDokumente(
                                        $event,
                                        rueckforderungFormular.id,
                                        rueckforderungDokumentTyp.ERWERBSERSATZ_DOKUMENTE
                                    )
                                "
                                [files]="rueckforderungErwerbsersatzDokumente"
                                [readOnly]="readOnlyDocument"
                                [readOnlyDelete]="
                                    !canDeleteDocuments(rueckforderungFormular)
                                "
                            >
                            </dv-multiple-file-upload>
                        </div>
                    </div>
                </div>
            </div>
            <!--            Verfuegungen-->
            <div
                class="col-md-8 col-md-offset-2"
                *ngIf="
                    rueckforderungFormular.hasBeenProvisorisch ||
                    rueckforderungFormular.status === 'VERFUEGT'
                "
            >
                <div class="row" id="verfuegungen">
                    <div class="col-sm-12">
                        <h2 translate class="margin-top-50">
                            NOTVERORDNUNG_VERFUEGUNG
                        </h2>
                        <hr class="header" />
                        <div *ngIf="rueckforderungFormular.hasBeenProvisorisch">
                            <button
                                type="button"
                                class="dv-btn btn-link link-underline"
                                (click)="
                                    openProvisorischeVerfuegung(
                                        rueckforderungFormular
                                    )
                                "
                            >
                                <span
                                    translate="RUECKFORDERUNG_PROVISORISCHE_VERFUEGUNG_LINK_TEXT"
                                ></span>
                            </button>
                        </div>
                        <div
                            *ngIf="rueckforderungFormular.status === 'VERFUEGT'"
                        >
                            <button
                                type="button"
                                class="dv-btn btn-link link-underline"
                                (click)="
                                    openDefinitiveVerfuegung(
                                        rueckforderungFormular
                                    )
                                "
                            >
                                <span
                                    translate="RUECKFORDERUNG_DEFINITIVE_VERFUEGUNG_LINK_TEXT"
                                ></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Beschwerde -->
            <div
                class="col-md-8 col-md-offset-2"
                *ngIf="rueckforderungFormular.status === 'VERFUEGT'"
            >
                <div
                    class="row"
                    id="beschwerden"
                    *ngIf="showBeschwerde || isKantonBenutzer()"
                >
                    <div class="col-sm-12">
                        <h2 translate class="margin-top-50">
                            NOTVERORDNUNG_BESCHWERDE
                        </h2>
                        <hr class="header" />
                    </div>
                </div>
                <form
                    role="form"
                    #beschwerdeForm="ngForm"
                    [ngClass]="{'ng-submitted': beschwerdeForm.submitted}"
                    (ngSubmit)="saveBeschwerde(rueckforderungFormular)"
                    *ngIf="showBeschwerde"
                    validate
                >
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label
                                    class="required long-label"
                                    for="beschwerde_betrag_id"
                                >
                                    {{
                                        'RUECKFORDERUNG_BESCHWERDE_BETRAG'
                                            | translate
                                    }}</label
                                >
                                <input
                                    id="beschwerde_betrag_id"
                                    type="number"
                                    name="beschwerdeBetragName"
                                    #beschwerdeBetrag="ngModel"
                                    [(ngModel)]="
                                        rueckforderungFormular.beschwerdeBetrag
                                    "
                                    [disabled]="beschwerdeReadOnly"
                                    class="form-control"
                                    autofocus
                                    required
                                />
                                <dv-error-messages
                                    [errorObject]="beschwerdeBetrag.errors"
                                    inputId="beschwerde_betrag_id"
                                >
                                </dv-error-messages>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label
                                    class="long-label"
                                    for="beschwerde_bemerkung_id"
                                >
                                    {{
                                        'RUECKFORDER_BESCHWERDE_BEMERKUNG'
                                            | translate
                                    }}</label
                                >
                                <textarea
                                    md-no-autogrow
                                    class="form-control"
                                    rows="6"
                                    maxlength="2000"
                                    id="beschwerde_bemerkung_id"
                                    #beschwerdeBemerkung="ngModel"
                                    name="beschwerdeBemerkungName"
                                    [(ngModel)]="
                                        rueckforderungFormular.beschwerdeBemerkung
                                    "
                                    [disabled]="beschwerdeReadOnly"
                                >
                                </textarea>
                                <dv-error-messages
                                    [errorObject]="beschwerdeBemerkung.errors"
                                    inputId="beschwerde_bemerkung_id"
                                >
                                </dv-error-messages>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div
                                class="dv-error-messages"
                                *ngIf="showMessageFehlendeDokumenteBeschwerde"
                            >
                                <span>{{
                                    'RUECKFORDERUNG_FEHLENDE_DOKUMENTE'
                                        | translate
                                }}</span>
                            </div>
                            <dv-multiple-file-upload
                                (download)="download($event[0], $event[1])"
                                [title]="'RUECKOFORDERUNG_DOKUMENTE_BESCHWERDE'"
                                (delete)="delete($event)"
                                (uploadFile)="
                                    uploadRuckforderungDokumente(
                                        $event,
                                        rueckforderungFormular.id,
                                        rueckforderungDokumentTyp.BESCHWERDE_DOKUMENTE
                                    )
                                "
                                [files]="rueckforderungBeschwerdeDokumente"
                                [readOnly]="beschwerdeReadOnly"
                                [readOnlyDelete]="beschwerdeReadOnly"
                            >
                            </dv-multiple-file-upload>
                        </div>
                    </div>
                    <div class="row" *ngIf="!beschwerdeReadOnly">
                        <div class="col-sm-12 flexbreak">
                            <button
                                type="button"
                                class="dv-btn cancel-button"
                                *ngIf="isKantonBenutzer()"
                                (click)="
                                    abbrechenBeschwerde(rueckforderungFormular)
                                "
                            >
                                <span translate>LABEL_ABBRECHEN</span>
                            </button>
                            <dv-loading-button
                                *ngIf="isKantonBenutzer()"
                                [type]="'submit'"
                            >
                                <span translate
                                    >RUECKFORDERUNG_BESCHWERDE_SPEICHERN</span
                                >
                            </dv-loading-button>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-sm-12">
                        <button
                            type="button"
                            class="dv-btn cancel-button beschwerde-button"
                            *ngIf="
                                isKantonBenutzerUndBeschwerdeNichtausgeloest(
                                    rueckforderungFormular
                                ) &&
                                !beschwerdeAlreadyExist &&
                                !showBeschwerde
                            "
                            (click)="beschwerdeBearbeiten()"
                        >
                            <span translate
                                >RUECKFORDERUNG_BESCHWERDE_ERFASSEN</span
                            >
                        </button>
                        <button
                            type="button"
                            class="dv-btn cancel-button beschwerde-button"
                            *ngIf="
                                isKantonBenutzerUndBeschwerdeNichtausgeloest(
                                    rueckforderungFormular
                                ) &&
                                beschwerdeAlreadyExist &&
                                beschwerdeReadOnly
                            "
                            (click)="beschwerdeBearbeiten()"
                        >
                            <span translate
                                >RUECKFORDERUNG_BESCHWERDE_BEARBEITEN</span
                            >
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="
                showWarnungFormularKannNichtFreigegebenWerden(
                    rueckforderungFormular
                )
            "
        >
            <div class="col-md-12">
                <div class="well well-status-warten">
                    <span>{{
                        getTextWarnungFormularKannNichtFreigegebenWerden(
                            rueckforderungFormular
                        ) | translate
                    }}</span>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="dv-footer margin-top-50 flexbreak">
            <dv-loading-button
                *ngIf="
                    isInstitutionStufe1(rueckforderungFormular) ||
                    isInstitutionStufe2(rueckforderungFormular)
                "
                [type]="'submit'"
                [buttonDisabled]="
                    !enableRueckforderungAbsenden(rueckforderungFormular) ||
                    fristSchonErreicht(rueckforderungFormular)
                "
            >
                <span translate>LABEL_ABSENDEN</span>
            </dv-loading-button>
            <button
                type="button"
                class="dv-btn cancel-button"
                *ngIf="isInstitutionStufe1(rueckforderungFormular)"
                (click)="rueckforderungAbschliessen(rueckforderungFormular)"
            >
                <span translate>RUECKFORDERUNG_KEIN_GESUCH_BEANTRAGEN</span>
            </button>
            <dv-loading-button
                *ngIf="isPruefungKantonStufe1(rueckforderungFormular)"
                [type]="'submit'"
            >
                <span translate>RUECKFORDERUNG_GEPRUEFT</span>
            </dv-loading-button>
            <button
                *ngIf="isProvVerfuegenPossible(rueckforderungFormular)"
                type="button"
                class="dv-btn cancel-button"
                (click)="provisorischVerfugen(rueckforderungFormular)"
            >
                <span translate>RUECKFORDERUNG_PROVISORISCH_VERFUEGEN</span>
            </button>
            <dv-loading-button
                *ngIf="
                    isPruefungKantonStufe2(rueckforderungFormular) ||
                    isProvisorischVerfuegtStufe2(rueckforderungFormular)
                "
                [type]="'submit'"
            >
                <span translate>RUECKFORDERUNG_FREIGEBEN_ZUR_VERFUEGUNG</span>
            </dv-loading-button>
            <button
                type="button"
                class="dv-btn cancel-button"
                *ngIf="isKantonBenutzer()"
                (click)="fristVerlaengern(rueckforderungFormular)"
            >
                <span translate>RUECKFORDERUNG_FRIST_VERLAENGERN</span>
            </button>
            <button
                *ngIf="isPruefungKantonStufe2(rueckforderungFormular)"
                type="button"
                class="dv-btn cancel-button"
                (click)="resetStatus(rueckforderungFormular)"
            >
                <span translate>RUECKFORDERUNG_RESET_STATUS</span>
            </button>
            <button
                *ngIf="showButtonZurueckholen(rueckforderungFormular)"
                type="button"
                class="dv-btn cancel-button"
                (click)="formularZurueckholen(rueckforderungFormular)"
            >
                <span translate>RUECKFORDERUNG_ZURUECKHOLEN</span>
            </button>
            <button
                *ngIf="
                    rueckforderungFormular.uncheckedDocuments &&
                    isKantonBenutzer()
                "
                type="button"
                class="dv-btn cancel-button"
                (click)="setDokumenteGeprueft(rueckforderungFormular)"
            >
                <span translate>DOKUMENTE_GEPRUEFT</span>
            </button>
            <button
                type="button"
                class="dv-btn cancel-button"
                [uiSref]="'notrecht.list'"
                translate
            >
                ZURUECK_ONLY
            </button>
        </div>
    </form>
</dv-full-height-container>
